import React, { createContext, useContext, useState, useEffect } from 'react';
import { setupLoader } from '../api/axiosInstance';

const LoaderContext = createContext();

export const useLoader = () => {
  return useContext(LoaderContext);
};

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = () => setLoading(true);
  const hideLoader = () => setLoading(false);

  // Setup loader for axios
  useEffect(() => {
    setupLoader(showLoader, hideLoader);
  }, []);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader, loading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};
