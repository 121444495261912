import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Stack, Typography, Link } from "@mui/material";
import { Button, TextInput, Logo, CarWrapper } from '../../../components';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "./SignUpScreen.module.scss";
import { useNavigate } from "react-router-dom";
import { register } from "../../../api/services/authService";
import Toaster from "../../../components/Toaster";

import {
  emailValidation,
  passwordValidation,
  fullNameValidation,
  addressValidation,
  phoneNumberValidation,
  cnicValidation,
  licenseValidation
} from "../../../utils/validation"

const validationSchema = Yup.object().shape({
  fullName: fullNameValidation,
  email: emailValidation,
  address: addressValidation,
  phoneNumber: phoneNumberValidation,
  password: passwordValidation,
  cnic: Yup.string().when('tabValue', {
    is: 1,
    then: cnicValidation,
  }),
  license: Yup.string().when('tabValue', {
    is: 1,
    then: licenseValidation,
  }),
});

const SignUp = () => {
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate()

  const handleChangeTab = (event, newValue, resetForm) => {
    setTabValue(newValue);
    resetForm();
  };

  const handleSignup = async (values) => {
    console.log('SignUp form values:', values);
    // try{
    const formData = {
      name: values.fullName.trim(),
      phoneNo: values.phoneNumber,
      email: values.email.trim(),
      password: values.password,
      role: values.role,
      address: values.address.trim(),
      cnic: values.cnic,
      licenseNo: values.license
    }

    await Toaster.handleApiCall(() => register(formData),
      "Successfully signed up!",
      (response) => {
        navigate('/login')
        // navigate("/profile", { state: { name: response.data.name } });
      }
    );
  };
  //     const response = await register(formData);
  //     console.log("success", response)
  //     toast.success(response?.message);
  //     navigate("/profile", { state: { name: response.data.name } });

  //   } catch (e) {
  //     toast.error(e?.response?.data?.message)
  //   }
  // };


  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Stack className={styles.CompanyLogo}>
          <Logo width={100} />
        </Stack>

        <Grid
          container
          spacing={2}
        >
          <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: '0 36px', md: '0' } }}>
            {/* <Grid size={{ xs: 12, md: 6 }} sx={{ margin: { xs: '0 36px', md: 'auto' }, marginTop: { xs: 'auto', md: '50px' }}} > */}

            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Stack
                spacing={2}
                sx={{ width: { xs: "100%", md: "78%" } }}
                className={styles.signupContainer}
              >
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  Sign Up
                </Typography>

                <Formik
                  initialValues={{
                    fullName: "",
                    email: "",
                    address: "",
                    phoneNumber: "",
                    password: "",
                    cnic: "",
                    license: "",
                    role: "",
                  }}
                  validationSchema={validationSchema}
                  // onSubmit={handlesignup}
                  onSubmit={(values) => {
                    values.role = tabValue === 0 ? "consumer" : "dealer";
                    handleSignup(values);
                  }}
                  validateOnMount={true}
                >
                  {({ handleChange, handleSubmit, handleBlur, values, errors, isValid, resetForm, dirty }) => (
                    <>
                      <Tabs value={tabValue} onChange={(event, newValue) => {
                        handleChangeTab(event, newValue, resetForm);
                        // values.isDealer = newValue === 1;
                        // setTouched({});
                      }}
                        centered
                      >
                        <Tab label="Consumer" sx={{ minWidth: { xs: 80, sm: 120, md: 200, lg: 200, }, }} />
                        <Tab label="Dealer" sx={{ minWidth: { xs: 80, sm: 120, md: 200, lg: 200, }, }} />
                      </Tabs>

                      <form onSubmit={handleSubmit} autoComplete='off' autoCapitalize="off">
                        <Stack
                          spacing={2}
                          sx={{ marginTop: { xs: "30px" } }}
                        >
                          <Grid container spacing={2}>
                            <Grid item size={{ xs: 12, md: 6 }}>
                              <Stack spacing={0.5} sx={{ width: "100%" }}>
                                <Typography variant="p">Full Name*</Typography>
                                <TextInput
                                  name="fullName"
                                  type="text"
                                  value={values.fullName}
                                  onChange={handleChange("fullName")}
                                  onBlur={handleBlur}
                                  placeholder="Enter your full name"
                                  error={values.fullName && Boolean(errors.fullName)}
                                  helperText={values.fullName && errors.fullName}
                                />
                              </Stack>
                            </Grid>

                            <Grid item size={{ xs: 12, md: 6 }}>
                              <Stack spacing={0.5} sx={{ width: "100%" }}>
                                <Typography variant="p">Email Address*</Typography>
                                <TextInput
                                  name="email"
                                  // type="email"
                                  value={values.email}
                                  onChange={handleChange("email")}
                                  onBlur={handleBlur}
                                  placeholder="Enter your email address"
                                  error={values.email && Boolean(errors.email)}
                                  helperText={values.email && errors.email}
                                />
                              </Stack>
                            </Grid>
                          </Grid>

                          {tabValue === 1 && (
                            <>
                              <Grid container spacing={2}>
                                <Grid item size={{ xs: 12, md: 6 }}>
                                  <Stack spacing={0.5} sx={{ width: "100%" }}>
                                    <Typography variant="p">NIC*</Typography>
                                    <TextInput
                                      name="cnic"
                                      type="text"
                                      value={values.cnic}
                                      onChange={handleChange("cnic")}
                                      onBlur={handleBlur}
                                      placeholder="Enter your NIC"
                                      error={
                                        values.cnic && Boolean(errors.cnic)
                                      }
                                      helperText={values.cnic && errors.cnic}
                                    />
                                  </Stack>
                                </Grid>

                                <Grid item size={{ xs: 12, md: 6 }}>
                                  <Stack spacing={0.5} sx={{ width: "100%" }}>
                                    <Typography variant="p">License No*</Typography>
                                    <TextInput
                                      name="license"
                                      type="text"
                                      value={values.license}
                                      onChange={handleChange("license")}
                                      onBlur={handleBlur}
                                      placeholder="Enter your license"
                                      error={
                                        values.license &&
                                        Boolean(errors.license)
                                      }
                                      helperText={
                                        values.license && errors.license
                                      }
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>
                            </>
                          )}

                          <Grid item size={{ xs: 12, md: 12 }}>
                            <Stack spacing={0.5} sx={{ width: "100%" }}>
                              <Typography variant="p">Full Address*</Typography>
                              <TextInput
                                name="address"
                                type="text"
                                value={values.address}
                                onChange={handleChange("address")}
                                onBlur={handleBlur}
                                placeholder="Enter your full address"
                                error={values.address && Boolean(errors.address)}
                                helperText={values.address && errors.address}
                              />
                            </Stack>
                          </Grid>


                          <Grid container spacing={2}>
                            {/* <Grid item size={{ xs: 12, md: 6 }}>
                              <Stack spacing={2} sx={{ width: "100%" }}>
                                <Typography variant="p">Phone Number*</Typography>
                                <TextInput
                                  name="phoneNumber"
                                  type="text"
                                  value={values.phoneNumber}
                                  onChange={handleChange("phoneNumber")}
                                  onBlur={handleBlur}
                                  placeholder="+1 000 000 (0000)"
                                  error={
                                    values.phoneNumber &&
                                    Boolean(errors.phoneNumber)
                                  }
                                  helperText={
                                    values.phoneNumber && errors.phoneNumber
                                  }
                                />
                              </Stack>
                            </Grid> */}
                            <Grid item size={{ xs: 12, md: 6 }}>
                              <Stack spacing={0.5} sx={{ width: "100%" }}>
                                <Typography variant="p">Phone Number*</Typography>
                                <TextInput
                                  name="phoneNumber"
                                  type="text"
                                  value={values.phoneNumber}
                                  onChange={(e) => {
                                    let inputValue = e.target.value;
                                    if (!inputValue.startsWith("+1")) {
                                      inputValue = `+1${inputValue.replace(/[^\d]/g, "")}`;
                                    } else {
                                      inputValue = `+1${inputValue.slice(2).replace(/[^\d]/g, "")}`;
                                    }
                                    handleChange("phoneNumber")(inputValue);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="+10000000000"
                                  error={values.phoneNumber && Boolean(errors.phoneNumber)}
                                  helperText={values.phoneNumber && errors.phoneNumber}
                                />
                              </Stack>
                            </Grid>


                            <Grid item size={{ xs: 12, md: 6 }}>
                              <Stack spacing={0.5} sx={{ width: "100%" }}>
                                <Typography variant="p">Password*</Typography>
                                <TextInput
                                  name="password"
                                  type="password"
                                  value={values.password}
                                  onChange={handleChange("password")}
                                  onBlur={handleBlur}
                                  placeholder="Enter your password"
                                  error={
                                    values.password && Boolean(errors.password)
                                  }
                                  helperText={
                                    values.password && errors.password
                                  }
                                />
                              </Stack>
                            </Grid>
                          </Grid>

                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            // disabled={!isValid}
                            disabled={!(isValid && dirty)}
                            sx={{ marginTop: "40px !important" }}
                          >
                            Sign Up
                          </Button>
                        </Stack>
                      </form>
                    </>
                  )}
                </Formik>

                <Typography
                  variant="body3"
                  sx={{ textAlign: "center", marginTop: "46px !important", marginBottom: "20px !important" }}
                >
                  Already have an account?{" "}
                  <Link href="/login" sx={{ fontWeight: 700 }}>
                    Sign In
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid
            size={{ xs: 12, md: 5 }}
            sx={{ marginLeft: { xs: "36px", md: "auto" } }}
          >
            <CarWrapper />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignUp;
