import React, { useEffect, useState, useRef } from 'react';
import { TextInput as TextField, Button, Toaster, Footer, Card, Sidebar } from "../../../components";
import { Stack, Avatar, Typography, Box, Container, FormHelperText, Link } from '@mui/material';
import Grid from "@mui/material/Grid2";
import { updateUser, uploadUserImage } from '../../../api/services/userService';
import { useAuth } from '../../../context/AuthContext';
import Styles from "./ProfileScreen.module.scss"
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    fullNameValidation,
    addressValidation,
    phoneNumberValidation,
    isImage
} from "../../../utils/validation"
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
    fullName: fullNameValidation,
    address: addressValidation,
    phoneNumber: phoneNumberValidation,
});

const ProfileScreen = () => {
    const formikRef = useRef();
    const initialValuesRef = useRef();
    const { user, setUser } = useAuth();
    const [FormChanges, setFormChanges] = useState(false);

    const handleGetUser = async () => {
        const initialFormValues = {
            fullName: user?.name || '',
            email: user?.email || '',
            phoneNumber: user?.phoneNo || '',
            address: user?.address || '',
        };

        formikRef.current.setValues(initialFormValues);
        initialValuesRef.current = initialFormValues;
    }

    const handleUpdateUser = async (values) => {
        const formData = {
            name: values.fullName,
            phoneNo: values.phoneNumber,
            email: values.email,
            address: values.address,
        };
        // console.log('Form values:', values);

        const responseData = await Toaster.handleApiCall(() => updateUser(formData),
            "Profile updated successfully!",
            (response) => {
                initialValuesRef.current = values;
                setFormChanges(false);
            }
        );

        if (responseData && responseData.data) {
            setUser(responseData.data)
        }
    }

    const menuItems = [
        { section: "profile", label: "My Profile" },
        { section: "stats", label: "Stats" },
        { section: "bankDetail", label: "Bank Detail" },
        { section: "history", label: "History" },
    ];

    const [selectedSection, setSelectedSection] = useState("profile");

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        console.log('file', file)

        if (!file) {
            return;
        };

        if (!isImage(file.name)) {
            toast.error("Invalid file type");
            return
        };

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {

                const response = await uploadUserImage(formData);
                console.log("success", response);
                toast.success(response?.message);
                setUser(prevUser => ({
                    ...prevUser,
                    image: response?.data?.image
                }))
            } catch (e) {
                const message = e.response?.data?.message || 'An error occurred';
                console.error(e);
                toast.error(message);
            }
        }
    };


    const handleFormChange = (values) => {
        const isDifferent = JSON.stringify(values) !== JSON.stringify(initialValuesRef.current);
        setFormChanges(isDifferent);
    };


    useEffect(() => {
        handleGetUser();
    }, [user]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Container>
                    <Grid container sx={{ margin: '1.5rem auto' }}>
                        <Grid item size={{ xs: 12, md: 3 }} sx={{ padding: "16px", color: "white", display: { xs: 'none', md: 'flex' }, background: '#cccccc52', borderRadius: "12px 0 0 12px" }}>
                            <Sidebar selectedSection={selectedSection} setSelectedSection={setSelectedSection} menuItems={menuItems} />
                        </Grid>

                        <Grid item size={{ xs: 12, md: 9 }} >
                            <Card
                                header='My Profile'
                                sx={{ boxShadow: 'none', border: '1px solid #D2D2D2' }}
                            >
                                <Formik
                                    innerRef={formikRef}
                                    initialValues={{ fullName: '', email: '', address: '', phoneNumber: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleUpdateUser(values);
                                        setSubmitting(false);
                                    }}
                                    onChange={() => handleFormChange(formikRef.current.values)}
                                >
                                    {({ handleChange, handleSubmit, handleBlur, values, errors, isValid }) => (
                                        <form onSubmit={handleSubmit} autoCapitalize='off' autoComplete='off'>
                                            <Grid container spacing={2} sx={{ gap: '24px', mt: '24px' }}>
                                                <Grid item size={{ xs: 12, md: 6 }} container alignItems="center" sx={{ width: '100% !important' }}>
                                                    <Grid item>
                                                        <Avatar
                                                            src={user?.image}
                                                            sx={{ width: 100, height: 100, bgcolor: "lightgray", margin: "auto", marginRight: 3 }}
                                                        />
                                                    </Grid>
                                                    <Grid item size={{ xs: 12, md: 6 }} sx={{ width: 'auto' }}>
                                                        <Button
                                                            component="label"
                                                            // fullWidth
                                                            sx={{ textTransform: 'capitalize' }}
                                                        >
                                                            Choose File
                                                            <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2} sx={{ width: '100%' }}>
                                                    <Grid item size={{ xs: 12, md: 6 }}>
                                                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                            <Typography variant="p">Full Name</Typography>
                                                            <TextField
                                                                name="fullName"
                                                                type="text"
                                                                value={values.fullName}
                                                                // onChange={handleChange}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleFormChange({ ...values, fullName: e.target.value });
                                                                }}
                                                                onBlur={handleBlur}
                                                                placeholder="Enter your full name"
                                                                error={values.fullName && Boolean(errors.fullName)}
                                                                helperText={values.fullName && errors.fullName}
                                                            />
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item size={{ xs: 12, md: 6 }}>
                                                        <Stack spacing={0.5} sx={{ width: "100%", position: "relative" }}>
                                                            <Typography variant="p">Email Address</Typography>
                                                            <TextField
                                                                name="email"
                                                                type="email"
                                                                value={values.email}
                                                                disabled
                                                                placeholder="Enter your email address"
                                                                // helperText="For edit email, please contact admin."
                                                                sx={{
                                                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                                                        backgroundColor: "#f0f0f0",
                                                                    }
                                                                }}
                                                            />
                                                            <FormHelperText className={Styles.HelperTextStyle} >
                                                                For edit email, please contact <Link>admin</Link>.
                                                            </FormHelperText>

                                                        </Stack>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2} sx={{ width: '100%', gap: "30px" }}>
                                                    <Grid item size={{ xs: 12, md: 12 }}>
                                                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                            <Typography variant="p">Full Address</Typography>
                                                            <TextField
                                                                name="address"
                                                                type="text"
                                                                value={values.address}
                                                                // onChange={handleChange}
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    handleFormChange({ ...values, address: e.target.value });
                                                                }}
                                                                onBlur={handleBlur}
                                                                placeholder="Enter your full address"
                                                                error={values.address && Boolean(errors.address)}
                                                                helperText={values.address && errors.address}
                                                            />
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item size={{ xs: 12, md: 12 }}>
                                                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                            <Typography variant="p">Phone Number</Typography>
                                                            <TextField
                                                                name="phoneNumber"
                                                                type="text"
                                                                value={values.phoneNumber}
                                                                onChange={(e) => {
                                                                    let inputValue = e.target.value;
                                                                    if (!inputValue.startsWith("+1")) {
                                                                        inputValue = `+1${inputValue.replace(/[^\d]/g, "")}`;
                                                                    } else {
                                                                        inputValue = `+1${inputValue.slice(2).replace(/[^\d]/g, "")}`;
                                                                    }
                                                                    handleChange("phoneNumber")(inputValue);
                                                                    handleFormChange({ ...values, phoneNumber: inputValue });
                                                                }}
                                                                onBlur={handleBlur}
                                                                placeholder="+10000000000"
                                                                error={values.address && Boolean(errors.phoneNumber)}
                                                                helperText={values.address && errors.phoneNumber}
                                                            />
                                                        </Stack>
                                                    </Grid>
                                                </Grid>

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={!isValid || !FormChanges}
                                                    sx={{ marginTop: "40px !important" }}
                                                >
                                                    Update Changes
                                                </Button>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default ProfileScreen;
