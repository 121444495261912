import React from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel, Stack, FormHelperText } from '@mui/material';

const Checkbox = ({ options = [], selectedValues = [], onChange, sx, error, helperText }) => {
  const handleCheckboxChange = (value) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    onChange(updatedValues);
  };

  return (
    <>
      {options.map((option, index) => (
        <FormControlLabel
          sx={sx}
          key={index}
          control={
            <MuiCheckbox
              checked={selectedValues.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
              onMouseDown={(event) => event.preventDefault()}
            />
          }
          label={option.label}
        />
      ))}
      <Stack>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
      </Stack>
    </>
  );
};

export default Checkbox;
