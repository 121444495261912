// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toaster_singleLineToast__AHy1i {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/components/Toaster/Toaster.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACJ","sourcesContent":[".singleLineToast {\n    max-width: 600px; \n    white-space: nowrap; \n    overflow: hidden; \n    text-overflow: ellipsis; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singleLineToast": `Toaster_singleLineToast__AHy1i`
};
export default ___CSS_LOADER_EXPORT___;
