import React from "react";
import ImageCarousel from "../../../components/ImageCarousel";
import { Box, Container, Stack, Typography, Button, Grid2 as Grid, List, ListItem, ListItemIcon, ListItemText, Divider, Card as MuiCard, CardContent } from "@mui/material";
import { Card, Footer, Navbar } from "../../../components";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Car from '../../../assets/images/car.svg'
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const AdDetail = () => {
  const navigate = useNavigate();
  const images = [Car, Car, Car, Car, Car];

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 300,
    border: "1px solid #22B14B",
    margin: "24px auto",
  };

  const carData = {
    images: [
      "/path/to/image1.jpg",
      "/path/to/image2.jpg",
      "/path/to/image3.jpg",
    ],
    title: "2022 Lamborghini Aventador",
    startingBid: "590,999",
    bidTracker: [
      { amount: "$710k" },
      { amount: "$800k" },
      { amount: "$850k" },
      { amount: "$841k" },
    ],
    engineCapacity: "4000cc",
    mileage: "121100 MI",
    fuelType: "Petrol",
    driveType: "AWD",
    availability: true,
    make: "Lamborghini",
    transmission: "Automatic",
    modelYear: 2022,
    seatCapacity: "2 Seats",
    specifications: [
      { key: "engine", label: "2.4 Ltr", icon: "mdi:engine" },
      { key: "mileage", label: "121100 MI", icon: "mdi:road" },
      { key: "fuel", label: "Petrol", icon: "mdi:gas-station" },
      { key: "drive", label: "AWD", icon: "mdi:car-traction-control" },
      { key: "availability", label: "Yes", icon: "mdi:magnify" },
    ],
    features: [
      { key: "ABS", label: "ABS", icon: "mingcute:abs-fill" },
      { key: "PowerWindows", label: "Power Windows", icon: "mdi:car-door" },
      {
        key: "AirConditioning",
        label: "Air Conditioning",
        icon: "material-symbols:ac-unit",
      },
      {
        key: "Navigation",
        label: "Navigation System",
        icon: "mdi:navigation-outline",
      },
      { key: "AirBags", label: "Air Bags", icon: "mdi:airbag" },
      {
        key: "CruiseControl",
        label: "Cruise Control",
        icon: "mdi:car-cruise-control",
      },
    ],
    timeRemaining: "1d 23h",
  };

  const CallDealerPage = () => {
    navigate("/detail");
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          bgcolor="#fff"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          padding={{ xs: '1rem 0.5rem', md: 4 }}
          width="100%"
          marginTop={4}
          marginBottom={5}
        >
          <Grid
            size={{ xs: 12, md: 7 }}
            sx={{ margin: { xs: "0", md: "0" } }}
          // paddingRight={{ xs: 0, md: 4 }}
          >
            <Stack>
              <ImageCarousel images={images} />
            </Stack>
            <Stack paddingTop="36px">
              <Grid
                container
                spacing={2}
                marginBottom={2}
                justifyContent="space-between"
              >
                {carData.specifications.map((spec) => (
                  <Grid item xs={6} md={2} key={spec.key}>
                    <Stack display="flex" alignItems="center" gap="10px">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding="15px"
                        border="1px solid #EBEBEB"
                        borderRadius="10px"
                      >
                        <Icon
                          icon={spec.icon}
                          style={{ fontSize: "24px", color: "#333" }}
                        />
                      </Box>
                      <Typography variant="body2">{spec.label}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>

              {/* Car Specifications */}
              <Grid container spacing={2} display="block">
                <Grid item xs={6}>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Make <Typography variant="p3">{carData.make}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Transmission{" "}
                    <Typography variant="p3">{carData.transmission}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Model Year{" "}
                    <Typography variant="p3">{carData.modelYear}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Seat Capacity{" "}
                    <Typography variant="p3">{carData.seatCapacity}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Engine Capacity{" "}
                    <Typography variant="p3">
                      {carData.engineCapacity}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>

              {/* Features */}
              <Grid margin="20px 0">
                <Typography variant="h6" fontWeight="bold" marginBottom={1}>
                  Features
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {carData.features.map((feature) => (
                    <Stack
                      display="flex"
                      direction="row"
                      alignItems="center"
                      gap={1.6}
                      width="48%"
                    >
                      <Stack
                        key={feature.key}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding="15px"
                        border="1px solid #EBEBEB"
                        borderRadius="10px"
                      >
                        <Icon
                          icon={feature.icon}
                          style={{ fontSize: "24px" }}
                        />
                      </Stack>
                      <Typography variant="body2">{feature.label}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            </Stack>
          </Grid>

          <Grid
            size={{ xs: 12, md: 5 }}
            sx={{ margin: { xs: "0 0.2rem", md: "0" } }}
          >
            {/* Car Details */}
            <Box flex="1">
              <MuiCard sx={{ mb: 0, boxShadow: 'none' }}>
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                  mb={2}

                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <AccessTimeIcon color="success" fontSize="small" />
                    <Typography variant="body2">
                      {carData.timeRemaining} remaining
                    </Typography>
                  </Stack>
                  <Button
                    variant="outlinedSecondary"
                    color="secondary"
                    size="small"
                  >
                    Copy Link
                  </Button>
                </Stack>

                <Stack mb={2}>
                  <Typography variant="h8" fontWeight="bold">
                    {carData.title}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="body6">Starting BID</Typography>
                  <Typography variant="h7">
                    USD {carData.startingBid}
                  </Typography>
                </Stack>
              </MuiCard>
              <List>
                <Divider sx={style} variant="middle" component="li" />
              </List>
              <Stack direction="column" alignItems="center" mb={5}>
                <Typography variant="h7">
                  You haven't made any bid yet
                </Typography>
              </Stack>

              <Card sx={{ backgroundColor: "#F2F2F2" }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Place Bid
                </Button>
              </Card>
            </Box>
          </Grid>
        </Grid>
        {/* <Box></Box> */}
      </Container>
    </>
  );
};

export default AdDetail;
