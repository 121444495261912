import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText } from '@mui/material';

const RadioInput = ({ name, label, value, onChange, options, error, helperText, row = true, ...props }) => {
    return (
        <FormControl component="fieldset" error={Boolean(error)}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                name={name}
                value={value || ''}
                onChange={(event) => {
                    onChange({
                        target: {
                            name,
                            value: event.target.value,
                        },
                    });
                }}
                row={row}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        {...props}
                    />
                ))}
            </RadioGroup>
            {/* {error && <FormHelperText>{error}</FormHelperText>} */}
            {error && <FormHelperText error>{helperText}</FormHelperText>}

        </FormControl>
    );
};

export default RadioInput;
