// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdViewCard_detailCar__iOIl2:not(:last-child) {
  border-right: 1px solid #DEDEDE;
  padding-right: 20px;
  margin: 0;
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/AdViewCard/AdViewCard.module.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AAAR","sourcesContent":[".detailCar{\n    &:not(:last-child){\n        border-right: 1px solid #DEDEDE;\n        padding-right: 20px;\n        margin: 0;\n        margin-right: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailCar": `AdViewCard_detailCar__iOIl2`
};
export default ___CSS_LOADER_EXPORT___;
