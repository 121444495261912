import * as React from "react";
import { useState, useEffect } from "react";
import { Stack, Box, Typography, Container, Grid2 as Grid } from "@mui/material";
import Checkbox from "../../../components/Checkbox"
import { Button, TextInput, SelectInput, ImageUpload } from "../../../components";
import { useAuth } from '../../../context/AuthContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { reservedBidValidation, modelValidation, modelYearValidation, makeValidation, transmissionValidation, engineCapacityValidation, engineTypeValidation, bodyTypeValidation, mileageValidation, locationValidation, seatCapacityValidation } from "../../../utils/validation"
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures, uploadFile } from "../../../api/services/listingService";
import { viewAd, updateAd } from "../../../api/services/listingService";
import Toaster from "../../../components/Toaster";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
    reservedBid: reservedBidValidation,
    // auctionTime: auctionTimeValidation,
    modelYear: modelYearValidation,
    make: makeValidation,
    model: modelValidation,
    transmission: transmissionValidation,
    engineType: engineTypeValidation,
    bodyType: bodyTypeValidation,
    mileage: mileageValidation,
    location: locationValidation,
    engineCapacity: engineCapacityValidation,
    seatCapacity: seatCapacityValidation,
    files: Yup.array().min(1, 'Please upload at least one image'),
});

const CreateAd = () => {
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelptions] = useState([]);
    const [transmissionOptions, setTransmissionOptions] = useState([]);
    const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
    const [engineTypeOptions, setEngineTypeOptions] = useState([]);
    const [featuresOptions, setFeaturesOptions] = useState([]);
    const [adData, setAdData] = useState(null);
    const { listingId } = useParams();

    //  const initialValues = {
    //     files: [], reservedBid: '', auctionTime: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '', location: '', engineCapacity: '', seatCapacity: '', description: '', features: []
    //   };

    const initialValues = adData ? {
        files: adData.images || [],
        reservedBid: adData.reservedPrice || '',
        // auctionDuration: adData.auctionDuration || '',
        modelYear: adData.modelYear || '',
        make: adData.makes?.id || '',
        model: adData.models?.id || '',
        transmission: adData.transmission_types?.id || '',
        engineType: adData.engine_types?.id || '',
        bodyType: adData.body_types?.id || '',
        mileage: adData.mileage || '',
        location: adData.location || '',
        engineCapacity: adData.engineCapacity || '',
        seatCapacity: adData.seatCapacity || '',
        description: adData.description || '',
        exteriorColor: adData.exteriorColor || '',
        interiorColor: adData.interiorColor || '',
        // features: adData.features || []
        features: adData.features ? adData.features.map(feature => feature.id) : []
    } : {
        files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '', location: '', engineCapacity: '', seatCapacity: '', description: '', features: []
    };
    // console.log('initialValues', initialValues)

    const modelYearOptions = () => {
        const options = [];
        for (let year = 1990; year <= 2024; year++) {
            options.push({ value: year, label: `${year}` });
        }
        return options;
    };

    const seatCapacityOptions = () => {
        const options = [];
        for (let seat = 2; seat <= 12; seat++) {
            options.push({ value: seat, label: `${seat}` });
        }
        return options;
    };

    async function fetchImageAsFile(url) {
        try {
            // Fetch the file from your backend
            const response = await uploadFile(url); // This is the API call to your backend

            if (response.data && response.data.fileContent) {
                const { originalname, mimetype, fileContent } = response.data;
                const binaryFile = base64ToFile(fileContent, originalname, mimetype);
                return binaryFile;
            }
        } catch (error) {
            console.error("Error fetching file:", error);
            throw error;
        }
    }

    // Convert Base64 to a binary File object
    function base64ToFile(base64Data, fileName, mimeType) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 512) {
            const slice = byteCharacters.slice(i, i + 512);
            const byteNumbers = new Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteNumbers[j] = slice.charCodeAt(j);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        return new File(byteArrays, fileName, { type: mimeType });
    }

    const handleCreateAD = async (values) => {
        console.log("Values", values);
        const formData = new FormData();

        // Add form data fields first
        formData.append("reservedPrice", parseFloat(values.reservedBid));
        formData.append("modelYear", values.modelYear);
        formData.append("make", values.make);
        formData.append("model", values.model);
        formData.append("transmissionType", values.transmission);
        formData.append("engineType", values.engineType);
        formData.append("bodyType", parseInt(values.bodyType));
        formData.append("mileage", parseFloat(values.mileage));
        formData.append("location", values.location);
        formData.append("engineCapacity", parseFloat(values.engineCapacity));
        formData.append("seatCapacity", values.seatCapacity);
        formData.append("description", values.description);
        formData.append("featureIds", JSON.stringify(values.features));

        // Ensure files are processed before the API call
        if (Array.isArray(values.files)) {
            for (const file of values.files) {
                if (typeof file === "string") {
                    console.log('each file', file);
                    try {
                        console.log('Fetching image from URL for binary conversion:', file);
                        const binaryFile = await fetchImageAsFile(file);
                        formData.append("files", binaryFile); // Append file as binary
                        console.log('Appended fetched binary file:', binaryFile);
                    } catch (error) {
                        console.error("Error converting URL to binary file:", error);
                    }
                } else if (file instanceof File) {
                    console.log('Appending new binary file:', file);
                    formData.append("files", file);
                } else {
                    console.warn("Unexpected file format:", file);
                }
            }
        } else {
            console.error("files should be an array!");
        }

        // Ensure the API call happens after all files are appended
        try {
            await Toaster.handleApiCall(() => updateAd(listingId, formData),
                "Form submitted successfully.",
                (response) => {
                    // Handle the response if needed
                }
            );
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };


    const fetchModel = async (makeId) => {
        try {
            console.log("makeId", makeId)
            const data = await getModelByMakeId(makeId);
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name
            }));
            setModelptions(options);
        } catch (error) {
            console.error("Error fetching model data:", error);
        }
    };

    const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
        try {
            const data = await fetchFunction();
            const options = data?.data?.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(options);
        } catch (error) {
            console.error(`Error fetching ${errorMsg} data:`, error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await viewAd(listingId);
            console.log("ViewAd API", response.data);
            const data = response?.data || {};
            setAdData(data);

            if (data.makes?.id) {
                fetchModel(data.makes.id);
            }
        } catch (error) {
            console.error("Error fetching ad details:", error);
        }
    };

    useEffect(() => {
        if (listingId) {
            console.log("listingId", listingId)
            fetchData();
        }
    }, [listingId])

    useEffect(() => {
        fetchOptions(getMake, setMakeOptions, "make");
        fetchOptions(getTransmissionTypes, setTransmissionOptions, "transmission");
        fetchOptions(getBodyTypes, setBodyTypeOptions, "body type");
        fetchOptions(getEngineTypes, setEngineTypeOptions, "engine type");
        fetchOptions(getFeatures, setFeaturesOptions, "features");
    }, []);

    return (
        <>
            <Container >
                <Grid container sx={{ width: "100%" }}>
                    <Grid item size={{ xs: 12 }} sx={{ width: "100%" }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            //   validateOnMount={false}
                            onSubmit={handleCreateAD}
                        >

                            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                                        <Stack
                                            spacing={2}
                                            sx={{ marginTop: { xs: "30px" } }}
                                        >
                                            <Typography variant="h6" mb={2}>
                                                Upload Images
                                            </Typography>
                                            <ImageUpload
                                                maxImages={8}
                                                maxSizeMB={8}
                                                allowedFormats={['jpeg', 'jpg', 'png', 'gif']}
                                                value={values.files}
                                                setFieldValue={setFieldValue}
                                                // error={!!errors.images && !values.images}
                                                error={errors.files && touched.files}
                                                helperText={errors.files && touched.files && values.files.length === 0 ? errors.files : ''}
                                            />

                                            <Grid container spacing={2}>
                                                <Grid item size={{ xs: 12, md: 12 }}>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Reserved Bid* (Usd)</Typography>
                                                        <TextInput
                                                            placeholder="Please Enter a starting bid price"
                                                            name="reservedBid"
                                                            value={values.reservedBid}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            // error={!!errors.reservedBid && !values.reservedBid}
                                                            error={Boolean(errors.reservedBid)}
                                                            helperText={errors.reservedBid &&
                                                                (errors.reservedBid !== "Reserved bid is required" ? errors.reservedBid : '')
                                                            }
                                                        />
                                                    </Stack>
                                                </Grid>

                                                {/* <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <Typography variant="p1">Auction Time* (Hrs)</Typography>
                                                        <SelectInput
                                                            name="auctionTime"
                                                            label="Auction Time"
                                                            value={values.auctionTime}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={[
                                                                { value: "1800", label: "30 minutes" },
                                                                { value: "7200", label: "2 hours" },
                                                            ]}
                                                            error={!!errors.auctionTime && !values.auctionTime}
                                                        />
                                                    </Stack>
                                                </Grid> */}
                                            </Grid>

                                            <Typography variant="h4" mb={2} mt={2}>
                                                Vehicle Details
                                            </Typography>

                                            <Grid item size={{ xs: 12, md: 12 }}>
                                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                    <Typography variant="p1">Make*</Typography>
                                                    <SelectInput
                                                        name="make"
                                                        label="Please Select"
                                                        value={values.make}
                                                        // setFieldValue={(field, selectedMake) => {
                                                        //   setFieldValue("make", selectedMake);
                                                        //   setFieldValue("model", "");
                                                        //   fetchModel(selectedMake.value);
                                                        // }}
                                                        onChange={(event) => {
                                                            const selectedMake = event.target.value;
                                                            setFieldValue("make", selectedMake);
                                                            fetchModel(selectedMake);
                                                        }}
                                                        options={makeOptions}
                                                        error={!!errors.make && !values.make}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Model*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <SelectInput
                                                            name="model"
                                                            label="Please Select"
                                                            value={values.model}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            disabled={!values.make}
                                                            options={modelOptions}
                                                            error={!!errors.model && !values.model}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Model Year*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <SelectInput
                                                            name="modelYear"
                                                            label="Please Select"
                                                            value={values.modelYear}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={modelYearOptions()}
                                                            error={!!errors.modelYear && !values.modelYear}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid item size={{ xs: 12, md: 12 }}>
                                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                    <Typography variant="p1">Transmission*</Typography>
                                                    <SelectInput
                                                        name="transmission"
                                                        label="Please Select"
                                                        value={values.transmission}
                                                        onChange={handleChange}
                                                        // setFieldValue={setFieldValue}
                                                        options={transmissionOptions}
                                                        error={!!errors.transmission && !values.transmission}
                                                    />
                                                </Stack>
                                            </Grid>


                                            <Grid container spacing={2}>
                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Engine Type*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <SelectInput
                                                            name="engineType"
                                                            label="Please Select"
                                                            value={values.engineType}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={engineTypeOptions}
                                                            error={!!errors.engineType && !values.engineType}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Body Type*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <SelectInput
                                                            name="bodyType"
                                                            label="Please Select"
                                                            value={values.bodyType}
                                                            onChange={handleChange}
                                                            // setFieldValue={setFieldValue}
                                                            options={bodyTypeOptions}
                                                            // error={false}
                                                            error={!!errors.bodyType && !values.bodyType}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Mileage*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <TextInput
                                                            placeholder="Please Enter"
                                                            name="mileage"
                                                            value={values.mileage}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            // error={!!errors.mileage && !values.mileage}
                                                            error={Boolean(errors.mileage)}
                                                            helperText={errors.mileage &&
                                                                (errors.mileage !== "Mileage is required" ? errors.mileage : '')
                                                            }
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Location*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <TextInput
                                                            placeholder="Please Enter"
                                                            name="location"
                                                            value={values.location}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            error={!!errors.location && !values.location}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Grid item size={{ xs: 12, md: 12 }}>
                                                <Typography variant="p1">Engine Capacity* (cc)</Typography>
                                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                    <TextInput
                                                        placeholder="Please Enter"
                                                        name="engineCapacity"
                                                        value={values.engineCapacity}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        error={Boolean(errors.engineCapacity)} // Show error if there is any error for engineCapacity
                                                        helperText={
                                                            // Only show helperText for the specific errors
                                                            errors.engineCapacity &&
                                                            (errors.engineCapacity !== "Engine capacity is required" ? errors.engineCapacity : '')
                                                        }
                                                    // onBlur={handleBlur}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid item size={{ xs: 12, md: 12 }}>
                                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                    <Typography variant="p1">Seat Capacity*</Typography>
                                                    <SelectInput
                                                        name="seatCapacity"
                                                        label="Please Select"
                                                        value={values.seatCapacity}
                                                        onChange={handleChange}
                                                        // setFieldValue={setFieldValue}
                                                        options={seatCapacityOptions()}
                                                        error={!!errors.seatCapacity && !values.seatCapacity}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Exterior Color*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <TextInput
                                                            placeholder="Please Enter"
                                                            name="exteriorColor"
                                                            value={values.exteriorColor}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            error={!!errors.exteriorColor && !values.exteriorColor}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid item size={{ xs: 12, md: 6 }}>
                                                    <Typography variant="p1">Interior Color*</Typography>
                                                    <Stack spacing={0.5} sx={{ width: "100%" }}>
                                                        <TextInput
                                                            placeholder="Please Enter"
                                                            name="interiorColor"
                                                            value={values.interiorColor}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            error={!!errors.interiorColor && !values.interiorColor}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>


                                            <Typography variant="p1">Features</Typography>
                                            <Box>
                                                {/* <Stack direction={'row'} flexWrap={'wrap'} width={'50%'}> */}
                                                <Stack
                                                    direction={{ xs: 'column', md: 'row' }}
                                                    flexWrap="wrap"
                                                    sx={{ width: { xs: '100%', md: '60%' } }}
                                                >
                                                    <Checkbox
                                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                                        options={featuresOptions}
                                                        selectedValues={values.features}
                                                        onChange={(newFeatures) => setFieldValue("features", newFeatures)}
                                                    />
                                                </Stack>
                                            </Box>

                                            <Typography variant="p1">Description</Typography>
                                            <TextInput
                                                placeholder="Write description about your car"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                fullWidth
                                                multiline
                                                minRows="4"
                                            />
                                        </Stack>

                                    </Stack>


                                    <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                                        <Button
                                            variant="outlined"
                                            color="transparent"
                                            sx={{ width: '140px', }}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            // fullWidth
                                            // disabled={!(isValid && dirty)}
                                            sx={{ width: '230px', textTransform: 'capitalize' }}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default CreateAd
