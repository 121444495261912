// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmScreen_CompanyLogo__k5f2t {
  margin-left: 5%;
  margin-top: 1%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Auth/ConfirmScreen/ConfirmScreen.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;AACJ","sourcesContent":[".CompanyLogo{\n    margin-left: 5%;\n    margin-top: 1%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanyLogo": `ConfirmScreen_CompanyLogo__k5f2t`
};
export default ___CSS_LOADER_EXPORT___;
