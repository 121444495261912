import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Grid2 as Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ImageCarousel = ({ images = [] }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [startThumbnailIndex, setStartThumbnailIndex] = useState(0);
  const [thumbnailsPerPage, setThumbnailsPerPage] = useState(5);
  const carouselRef = useRef(null);

  useEffect(() => {
    const updateThumbnailsPerPage = () => {
      if (carouselRef.current) {
        const carouselWidth = carouselRef.current.offsetWidth;
        const thumbnailWidth = 110;
        setThumbnailsPerPage(Math.max(Math.floor(carouselWidth / thumbnailWidth), 1));
      }
    };

    const resizeObserver = new ResizeObserver(updateThumbnailsPerPage);
    if (carouselRef.current) resizeObserver.observe(carouselRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleNextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePreviousImage = () => {
    setActiveImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleThumbnailScroll = (direction) => {
    setStartThumbnailIndex((prevIndex) => {
      if (direction === "right") {
        return Math.min(prevIndex + 1, images.length - thumbnailsPerPage);
      } else {
        return Math.max(prevIndex - 1, 0);
      }
    });
  };

  return (
    <Box width="100%" margin="auto" ref={carouselRef}>
      <Box position="relative" width="100%" maxHeight="338px">
        <Box
          component="img"
          src={images[activeImageIndex]}
          alt="Main Display"
          width="100%"
          height="auto"
          maxHeight="338px"
          borderRadius="14px"
          sx={{
            boxShadow: 3
          }}
        />

        <IconButton
          onClick={handlePreviousImage}
          sx={{
            position: "absolute", top: "50%", left: "5px", transform: "translateY(-50%)", backgroundColor: "rgba(0,0,0,0.5)", color: "white", "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>

        <IconButton
          onClick={handleNextImage}
          sx={{
            position: "absolute", top: "50%", right: "5px", transform: "translateY(-50%)", backgroundColor: "rgba(0,0,0,0.5)", color: "white", "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center" marginTop="24px">
        {images.length > thumbnailsPerPage && (
          <IconButton onClick={() => handleThumbnailScroll("left")}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}

        <Grid container spacing={1} wrap="nowrap" width="100%">
          {images
            .slice(startThumbnailIndex, startThumbnailIndex + thumbnailsPerPage)
            .map((image, index) => (
              <Grid item key={index}>
                <Box
                  component="img"
                  src={image}
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleThumbnailClick(startThumbnailIndex + index)}
                  width="100%"
                  height="80px"
                  borderRadius="8px"
                  sx={{
                    objectFit: "cover",
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    border: activeImageIndex === startThumbnailIndex + index ? "2px solid #22B14B" : "none",
                    "&:hover": { transform: "scale(1.05)" },
                  }}
                />
              </Grid>
            ))}
        </Grid>

        {images.length > thumbnailsPerPage && (
          <IconButton onClick={() => handleThumbnailScroll("right")}>
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
