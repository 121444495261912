// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_CompanyLogo__DZif9 {
  margin-left: 5%;
  margin-top: 1%;
}
@media (max-width: 768px) {
  .Logo_CompanyLogo__DZif9 {
    margin: 60px auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;AACF;AACE;EAJF;IAKI,iBAAA;EAEF;AACF","sourcesContent":[".CompanyLogo {\n  margin-left: 5%;\n  margin-top: 1%;\n\n  @media (max-width: 768px) {\n    margin: 60px auto; \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanyLogo": `Logo_CompanyLogo__DZif9`
};
export default ___CSS_LOADER_EXPORT___;
