// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileScreen_HelperTextStyle__tAbmj {
  position: absolute;
  bottom: -20px;
  font-weight: 500 !important;
}
.ProfileScreen_HelperTextStyle__tAbmj a {
  color: #22B14B;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/Common/ProfileScreen/ProfileScreen.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EAEA,aAAA;EACA,2BAAA;AAAJ;AACI;EACI,cAAA;EACA,0BAAA;EACA,eAAA;AACR","sourcesContent":[".HelperTextStyle{\n    position: absolute;\n    // margin-left: 0;\n    bottom: -20px;\n    font-weight: 500 !important;\n    a{\n        color: #22B14B;\n        text-decoration: underline;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HelperTextStyle": `ProfileScreen_HelperTextStyle__tAbmj`
};
export default ___CSS_LOADER_EXPORT___;
