// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivateNavbar_imgProfile__NQe2g {
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/PrivateNavbar/PrivateNavbar.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":[".imgProfile{\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgProfile": `PrivateNavbar_imgProfile__NQe2g`
};
export default ___CSS_LOADER_EXPORT___;
