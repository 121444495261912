import * as React from "react";
import { useState, useEffect } from "react";
import { AppBar, Box, Toolbar, Badge, MenuItem, Menu, IconButton, Avatar, Typography, ImageListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiButton from "@mui/material/Button";
import { Modal, Button, TextInput } from "../../index";
import logo from "../../../assets/logo/logo.png";
import profile_avatar from "../../../assets/logo/profile_avatar.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';
import Styles from "./PrivateNavbar.module.scss";
import { UserMenu, MobileMenu, SellNowMenu } from ".././Menu";
import VinModal from "./VinModal";
import navbarData from './data.json'

const PrivateNavbar = ({ selectedSection, setSelectedSection }) => {
  // const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [sellNowAnchorEl, setSellNowAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [avatar, setAvatar] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  if (user?.roleName) {
    console.log(user?.roleName)
  }

  const pages = user?.roleName === "dealer" ? navbarData.dealerPages : navbarData.consumerPages;

  const handleNavigation = (pageName) => {
    const page = pages.find(page => page.name === pageName);
    if (page) {
      navigate(page.route);
    }
  };

  const menuItems = [
    { section: "profile", label: "My Profile", route: "/profile" },
    { section: "stats", label: "Stats", route: "/profile" },
    { section: "bankDetail", label: "Bank Detail", route: "/profile" },
    { section: "history", label: "History", route: "/profile" },
  ];

  const sellNowOptions = [
    { label: "VIN Number", description: "Auto-fetch car details with your", id: 1 },
    { label: "Manual", description: "Add all your car details by yours", id: 2 }
  ];


  useEffect(() => {
    setName(user?.name || '');
    setEmail(user?.email || '');
  }, [user]);

  const handleMenuOpen = (setter) => (event) => setter(event.currentTarget);
  const handleMenuClose = (setter) => () => setter(null);


  const handleMenuItemClick = (section) => {
    const selectedMenuItem = menuItems.find((item) => item.section === section);
    if (selectedMenuItem) {
      // setSelectedSection(section);
      handleMenuClose(setAnchorElUser)();
      navigate(selectedMenuItem.route);
    }
  };


  const handleSellNowOptionSelect = (id) => {
    if (id === 1) {
      setOpenModal(true);
    } else if (id === 2) {
      navigate('/ads/create');

    }
    handleMenuClose(setSellNowAnchorEl)();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleModalSubmit = () => {
    console.log("VIN Submitted");
    handleModalClose();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ flexGrow: 1, boxShadow: { xs: 'inherit', md: "none" }, backgroundColor: "#ffffff !important", paddingTop: { xs: '5px', md: '0px' } }}>
        <Toolbar>
          <IconButton
            size="large"
            color="inherit"
            onClick={handleMenuOpen(setMobileMoreAnchorEl)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <ImageListItem sx={{ width: { xs: '50px', sm: 'auto' } }}>
            <img src={logo} alt="Get A Bid Logo" />
          </ImageListItem>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "end", gap: "30px" }}>
            {pages.map((page) => (
              <MuiButton
                key={page.name}
                onClick={() => handleNavigation(page.name)}
                color="secondary"
                sx={{ my: 2, display: "block", fontFamily: "Nunito", fontSize: '18px' }}
              >
                {page.name}
              </MuiButton>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: "flex" } }}>
            <IconButton size="large" color="inherit">
              <Badge badgeContent={1} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              onClick={handleMenuOpen(setAnchorElUser)}
              color="inherit"
            >
              <img src={profile_avatar} alt="user profile logo" className={Styles.imgProfile} />
            </IconButton>
            {/* {isConsumer && ( */}
            {user?.roleName === 'consumer' && (<Button
              sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' } }}
              onClick={handleMenuOpen(setSellNowAnchorEl)}
            >
              Sell Now<KeyboardArrowDownIcon />
            </Button>)}
            {/* )} */}
          </Box>
        </Toolbar>
      </AppBar>

      {MobileMenu({ mobileMoreAnchorEl, handleMenuClose: handleMenuClose(setMobileMoreAnchorEl), handleNavigation, pages })}
      {UserMenu({ anchorElUser, handleMenuClose: handleMenuClose(setAnchorElUser), handleMenuItemClick, menuItems, selectedSection, name, email, avatar: user?.image, logout })}
      {SellNowMenu({ sellNowAnchorEl, handleMenuClose: handleMenuClose(setSellNowAnchorEl), handleSellNowOptionSelect, sellNowOptions })}
      <VinModal open={openModal} onClose={handleModalClose} onSubmit={handleModalSubmit} />

      <Box sx={{ marginTop: '100px' }} />
    </Box>
  );
};

export default PrivateNavbar;
