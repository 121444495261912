import React, { useState } from "react";
import {
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "../../components/Checkbox";
import Slider from "@mui/material/Slider";
// import Card from '../../components/Card'

const FilterSidebar = () => {
  const [selectedMakes, setSelectedMakes] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedTransmissions, setSelectedTransmissions] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [priceRange, setPriceRange] = useState([15000, 65000]);

  const makeOptions = [
    { label: "Toyota", value: "toyota", count: 19203 },
    { label: "Lamborghini", value: "lamborghini", count: 100 },
    { label: "BMW", value: "bmw", count: 221 },
    { label: "Ford", value: "ford", count: 41 },
    { label: "Honda", value: "honda", count: 12600 },
    { label: "Tesla", value: "tesla", count: 40 },
  ];

  const modelOptions = [
    { label: "Aventador", value: "aventador" },
    { label: "Porsche", value: "porsche" },
  ];

  const transmissionOptions = [
    { label: "Automatic", value: "automatic" },
    { label: "Manual", value: "manual" },
  ];

  const featuresOptions = [
    { label: "Cruise Control", value: "cruise_control" },
    { label: "Power Lock", value: "power_lock" },
    { label: "Air Bags", value: "air_bags" },
    { label: "Immobilizer Key", value: "immobilizer_key" },
    { label: "Power Mirrors", value: "power_mirrors" },
    { label: "Power Steering", value: "power_steering" },
  ];

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  return (
    <Card sx={{ backgroundColor: "#EDEEEF", width: "auto" }}>
      <Typography
        variant="p6"
        sx={{ fontWeight: "bold", mb: 2 }}
        backgroundColor="#22B14B"
        display="inline-block"
        width="100%"
        padding={2}
        textAlign={"center"}
      >
        Show Result By:
      </Typography>
      <Stack padding={2}>
        <Stack display={"flex"} gap={2}>
          <Accordion
            defaultExpanded
            sx={{ boxShadow: "none", borderRadius: "10px" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body5">Make</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Checkbox
                options={makeOptions.map((option) => ({
                  label: `${option.label} (${option.count})`,
                  value: option.value,
                }))}
                selectedValues={selectedMakes}
                onChange={setSelectedMakes}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ boxShadow: "none", borderRadius: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body5">Model</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Checkbox
                options={modelOptions}
                selectedValues={selectedModels}
                onChange={setSelectedModels}
              />
            </AccordionDetails>
          </Accordion>
          <Stack>
            <Typography marginBottom={2} variant="body5">
              Transmission
            </Typography>
            <Stack
              borderRadius={2.5}
              backgroundColor="#ffffff"
              padding={"12px 10px"}
            >
              <RadioGroup
                value={selectedTransmissions}
                onChange={(e) => setSelectedTransmissions(e.target.value)}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {transmissionOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </Stack>
          </Stack>
          <Stack>
            <Typography marginBottom={2} variant="body5">
              Feature
            </Typography>
            <Stack
              borderRadius={2.5}
              backgroundColor="#ffffff"
              padding={"12px 10px"}
            >
              <Checkbox
                options={featuresOptions}
                selectedValues={selectedFeatures}
                onChange={setSelectedFeatures}
              />
              <Typography
                variant="p2"
                style={{ textDecorationLine: "underline" }}
                marginTop={2}
              >
                More Choices...
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body5">Price Range</Typography>
            <Stack
              backgroundColor="white"
              borderRadius={2.5}
              padding={"12px 10px"}
              marginTop={2}
            >
              <Slider
                value={priceRange}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                min={15000}
                max={65000}
                sx={{ color: "green", left: '10px', width: '90%' }}
              />
              <Stack direction="row" justifyContent="space-between" marginTop={2.5} padding={'0 20px'}>
                <Typography variant="body8">${priceRange[0]}</Typography>
                <Typography variant="body8">-</Typography>
                <Typography variant="body8">${priceRange[1]}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FilterSidebar;
