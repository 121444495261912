import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { ImageListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import carImage from '../../assets/images/car.svg';

const CarWrapper = ({ heading1 = "Buy & Sell", heading2 = "your car with ease", paragraph = "This platform will help you to Buy & Sell your best car.", imageSrc = carImage, altText = "Car Image" }) => {
  return (
    <Grid
      container alignItems="center" justifyContent="center" direction="column" spacing={6}
      sx={{
        textAlign: 'center',
        padding: '0',
        maxWidth: { md: '550px', xl: '100%' },
        float: 'right',
        height: { xs: '100%', xl: 'auto' },
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Stack sx={{ width: { md: '100%' } }}>
        <Grid item>
          <Typography variant="h3" sx={{ textAlign: 'left' }}>
            {heading1}
            <br />
            {heading2}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" component="p" sx={{ textAlign: 'left', marginTop: '10px' }}>
            {paragraph}
          </Typography>
        </Grid>
        <Grid item>
          <ImageListItem>
            <img src={imageSrc} alt={altText} style={{ maxWidth: '100%', height: 'auto' }} />
          </ImageListItem>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default CarWrapper;
