import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateLayout = ({ role }) => {
    const { isAuthenticated, user } = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
      }
    
      if (role && user?.roleName !== role) {
        return <Navigate to="/" />;
      }
    return (
        <>
            <Outlet />
        </>
    );
};

export default PrivateLayout;
