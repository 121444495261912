import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Grid2 as Grid, Modal, Paper, FormHelperText, Stack } from '@mui/material';
import imageCompression from 'browser-image-compression';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

const FileUpload = ({ label, placeholder, name, maxImages, maxSizeMB = 1, allowedFormats = ['jpeg', 'jpg', 'png', 'gif'], setFieldValue, value = [], error, helperText }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const fileInputRef = useRef();

    const handleImageChange = async (event) => {
        const files = Array.from(event.target.files);
        let validFiles = [];

        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };


        for (const file of files) {
            const fileFormat = file.type.split('/')[1];
            if (!allowedFormats.includes(fileFormat)) {
                toast.dismiss();
                toast.error(`File format ${fileFormat} is not allowed.`)
            } else if (file.size > maxSizeMB * 1024 * 1024) {
                toast.dismiss();
                toast.error(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`)
                // alert(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`);
            } else {
                try {
                    const compressedBlob = await imageCompression(file, options);

                    const compressedFile = new File([compressedBlob], file.name, {
                        type: compressedBlob.type,
                        lastModified: file.lastModified,
                    });

                    validFiles.push(compressedFile);
                } catch (error) {
                    // console.error("Error compressing image:", error);
                    toast.error("An error occurred while compressing an image. Please try again.");
                }
            }
        }

        if ((value.length + validFiles.length) > maxImages) {
            toast.dismiss();
            toast.error(`You can upload a maximum of ${maxImages} image.`);
            return;
        }

        const newImages = [...value, ...validFiles];
        setFieldValue(name, newImages);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const removeImage = (index) => {
        const updatedImages = [...value];
        updatedImages.splice(index, 1);
        setFieldValue(name, updatedImages);
    };

    const getImageUrl = (file) => {
        return typeof file === 'string' ? file : URL.createObjectURL(file);
    };

    useEffect(() => {
        return () => {
            value.forEach((file) => {
                if (typeof file !== 'string') {
                    URL.revokeObjectURL(file);
                }
            });
        };
    }, [value]);

    return (
        <Box>
            <label style={{ cursor: 'pointer', display: 'block' }}>
                <Stack spacing={0.5} sx={{ width: "100%" }}>
                    <Typography variant="p1" gutterBottom>{label}</Typography>
                    <Box sx={{ border: '1px dashed #22B14B', padding: 2, textAlign: 'center', position: 'relative', borderRadius: 1 }}>
                        <input
                            hidden
                            ref={fileInputRef}
                            accept={allowedFormats.map(format => `image/${format}`).join(', ')}
                            multiple
                            type="file"
                            onChange={handleImageChange}
                        />
                        <IconButton color="primary" component="span" aria-label="upload picture">
                            <AddAPhotoIcon fontSize="large" />
                        </IconButton>
                        <Typography variant="body2" sx={{ color: '#22B14B' }}>{placeholder}</Typography>
                    </Box>
                </Stack>
            </label>

            {value.length > 0 && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {value.map((file, index) => (
                        <Grid item key={index}>
                            <Paper elevation={1} sx={{ position: 'relative' }}>
                                <img
                                    src={getImageUrl(file)}
                                    alt={`Uploaded ${index}`}
                                    style={{ width: 100, height: 80, borderRadius: 5 }}
                                    onClick={() => setPreviewImage(getImageUrl(file))}
                                />
                                <IconButton
                                    onClick={() => removeImage(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}

            {error && <FormHelperText error>{helperText}</FormHelperText>}

            <Modal open={!!previewImage} onClose={() => setPreviewImage(null)}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        height: '100%',
                    }}
                >
                    <IconButton
                        onClick={() => setPreviewImage(null)}
                        sx={{ position: 'absolute', top: 20, right: 20, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Full Size Preview"
                            style={{ maxWidth: '100%', maxHeight: '70%', borderRadius: 10 }}
                        />
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default FileUpload;
