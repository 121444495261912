import React from "react";
import { Container, Typography, Stack, Box } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import Car from "../../../assets/images/car.svg";
import {
  DirectionsCar as DirectionsCarIcon,
  LocalGasStation as LocalGasStationIcon,
  AllInclusive as AllInclusiveIcon,
  Speed as SpeedIcon,
} from "@mui/icons-material";

const MyBids = () => {
  return (
    <>
      <Container sx={{ py: 4 }}>
        <Box
          bgcolor={'#fff'} borderRadius={'12px'} boxShadow={'0px 4px 12px rgba(0, 0, 0, 0.1)'} padding={{ xs: 2, md: 4 }} width={'100%'}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
            My Bids
          </Typography>
          {/* <Grid item size={{ xs: 12, md: 9 }} paddingLeft={4.5}> */}
          {/* <Box width={"100%"}> */}
          <Stack spacing={2}>
            <AdViewCard
              image={Car}
              title="2022 Lamborghini Aventador"
              subtitle="Starting BID"
              price="USD 599,999"
              details={[
                {
                  key: "engine",
                  icon: DirectionsCarIcon,
                  value: "2.4 Ltr",
                },
                { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                {
                  key: "fuel",
                  icon: LocalGasStationIcon,
                  value: "Petrol",
                },
                { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
              ]}
              time="01:23 remaining"
            />
            <AdViewCard
              image={Car}
              title="2024 Porsche"
              subtitle="Starting BID"
              price="USD 599,999"
              details={[
                {
                  key: "engine",
                  icon: DirectionsCarIcon,
                  value: "2.4 Ltr",
                },
                { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                {
                  key: "fuel",
                  icon: LocalGasStationIcon,
                  value: "Petrol",
                },
                { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
              ]}
              time="02:20 remaining"
            />
            <AdViewCard
              image={Car}
              title="2024 Porsche"
              subtitle="Starting BID"
              price="USD 599,999"
              details={[
                {
                  key: "engine",
                  icon: DirectionsCarIcon,
                  value: "2.4 Ltr",
                },
                { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                {
                  key: "fuel",
                  icon: LocalGasStationIcon,
                  value: "Petrol",
                },
                { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
              ]}
              status="Sold"
            />
            <AdViewCard
              image={Car}
              title="2024 Porsche"
              subtitle="Starting BID"
              price="USD 599,999"
              details={[
                {
                  key: "engine",
                  icon: DirectionsCarIcon,
                  value: "2.4 Ltr",
                },
                { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                {
                  key: "fuel",
                  icon: LocalGasStationIcon,
                  value: "Petrol",
                },
                { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
              ]}
              status="In Review"
            />
          </Stack>
        </Box>
      </Container>
    </>
  );
};

export default MyBids;
