import React from "react";
import { Button as MUIButton } from '@mui/material';

const Button = ({ children, sx, variant = "contained", color = "primary", ...props }) => (
    <MUIButton
        variant={variant}
        color={color}
        sx={sx}
        {...props}
    >
        {children}
    </MUIButton>
);

export default Button;
