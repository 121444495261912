import React, { useState, useEffect } from "react";
import { Button, Container, Typography, Stack, Box } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import { fetchAd } from "../../../api/services/listingService";
import { useNavigate } from "react-router-dom";

const MyAd = () => {
  const [carsData, setCarsData] = useState([]);
  const navigate = useNavigate();

  const handleFetchAd = async () => {
    try {
      const response = await fetchAd();
      // setCarsData(response);
      const cars = response?.data || [];
      console.log("cars data", cars);
      setCarsData(cars);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const handlePlaceAd = () => {
    navigate("/ads/create");
  };

  useEffect(() => {
    handleFetchAd();
  }, []);

  return (
    <>
      <Container sx={{ py: 4 }}>
        <Box
          bgcolor={"#fff"}
          borderRadius={"12px"}
          boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
          padding={{ xs: 2, md: 4 }}
          width={"100%"}
        >
          {/* <Grid item size={{ xs: 12, md: 9 }} paddingLeft={4.5}> */}
          {/* <Box width={"100%"}> */}

          {carsData && carsData.length > 0 ? (
            <Stack spacing={2}>
              <Stack>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
                  My Ads
                </Typography>
              </Stack>
              {carsData?.map((car) => (
                <AdViewCard
                  key={car.id}
                  userId={car.user_id}
                  route={`/ads/${car.id}`}
                  listingId={car.id}
                  image={car?.images[0]}
                  title={car?.makes.name || "Unknown Model"}
                  subtitle="Starting BID"
                  price={`USD ${car?.reservedPrice || "0"}`}
                  details={[
                    { key: "engine", icon: "mdi:engine", value: car?.engineCapacity || "Unknown", },
                    { key: "mileage", icon: "mdi:road", value: car?.mileage || "Unknown", },
                    { key: "fuel", icon: "mdi:gas-station", value: car?.engine_types.name || "Unknown", },
                    { key: "drive", icon: "solar:wheel-bold", value: car?.driveType || "Unknown", },
                  ]}
                  time={car?.auctionDuration || "Unknown time"}
                // status={car?.status || "Available"}
                />
              ))}
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              spacing={2}
              sx={{ textAlign: "center", py: 4 }}
            >
              <Typography variant="h6" color="textSecondary">
                You haven't placed any ads yet!
              </Typography>
              <Typography color="textSecondary">
                Start by placing your first ad to reach potential buyers.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePlaceAd}
                sx={{ mt: 2 }}
              >
                Place Your Ad
              </Button>
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

export default MyAd;
