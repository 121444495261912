import React, { useState, useEffect } from "react";
import ImageCarousel from "../../../components/ImageCarousel";
import { Box, Container, Stack, Typography, Button, Grid2 as Grid, List, ListItem, ListItemText, Divider, Card as MuiCard, CardContent, } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Car from "../../../assets/images/car.svg";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { viewAd } from "../../../api/services/listingService";

const ViewAd = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [carData, setCarData] = useState(null);
  // const images = carData?.images || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await viewAd(id);
        console.log("hellllo", response.data);
        const cars = response?.data || [];
        setCarData(cars);
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };
    fetchData();
  }, [id]);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 300,
    border: "1px solid #22B14B",
    margin: "24px auto",
  };

  const bid = {
    bidTracker: [
      { amount: "$710k" },
      { amount: "$800k" },
      { amount: "$850k" },
      { amount: "$841k" },
    ],
  };

  const featureIcons = {
    "Air Conditioning": "material-symbols:ac-unit",
    "Cruise Control": "mdi:car-cruise-control",
    "Bluetooth Connectivity": "mdi:bluetooth",
    "Rear Camera": "mdi:camera-rear",
    "Navigation System": "mdi:navigation-outline",
    "Sunroof": "mdi:car-select",
    "Power Windows": "mdi:car-door",
    "ABS (Anti-lock Braking System)": "mingcute:abs-fill",
    "Airbags": "mdi:airbag",
    "Automatic Transmission": "mdi:car-cruise-control",
    "LED Headlights": "mdi:lightbulb",
    "Remote Keyless Entry": "mdi:lock-open-outline",
    "Push Button Start": "mdi:power",
    "Parking Sensors": "mdi:car-parking-lights",
    "Alloy Wheels": "mdi:car-tire-alert",
    "Traction Control": "mdi:car-traction-control",
    "Voice Recognition": "mdi:microphone",
    "Wireless Charging": "mdi:wireless",
    "Apple CarPlay/Android Auto": "mdi:apple-safari",
    "Heated Seats": "ph:seat-fill",
  };

  const transformedFeatures =
    carData?.features?.map((feature) => ({
      ...feature,
      icon: featureIcons[feature?.name] || "mdi:help", // Fallback icon
      label: feature.name,
      key: feature.id,
    })) || [];

  const specificationIcons = {
    engineCapacity: "mdi:engine",
    mileage: "mdi:road",
    fuel: "mdi:gas-station",
    drive: "solar:wheel-bold",
    availability: "mdi:magnify",
    transmission: "mdi:car-shift-pattern",
  };

  const transformedSpecifications = [
    {
      key: "engineCapacity",
      label: `${carData?.engineCapacity} Ltr`,
      icon: specificationIcons.engineCapacity,
    },
    {
      key: "mileage",
      label: `${carData?.mileage} MI`,
      icon: specificationIcons.mileage,
    },
    {
      key: "fuel",
      label: carData?.engine_types?.id || "N/A",
      icon: specificationIcons.fuel,
    },
    {
      key: "transmission",
      label: carData?.transmission_types?.id || "N/A",
      icon: specificationIcons.transmission,
    },
    {
      key: "availability",
      label: carData?.availability ? "Yes" : "No",
      icon: specificationIcons.availability,
    },
  ];

  // const carData = {
  //   images: [
  //     "/path/to/image1.jpg",
  //     "/path/to/image2.jpg",
  //     "/path/to/image3.jpg",
  //   ],
  //   title: "2022 Lamborghini Aventador",
  //   startingBid: "590,999",
  //   bidTracker: [
  //     { amount: "$710k" },
  //     { amount: "$800k" },
  //     { amount: "$850k" },
  //     { amount: "$841k" },
  //   ],
  //   engineCapacity: "4000cc",
  //   mileage: "121100 MI",
  //   fuelType: "Petrol",
  //   driveType: "AWD",
  //   availability: true,
  //   make: "Lamborghini",
  //   transmission: "Automatic",
  //   modelYear: 2022,
  //   seatCapacity: "2 Seats",
  //   specifications: [
  //     { key: "engine", label: "2.4 Ltr", icon: "mdi:engine" },
  //     { key: "mileage", label: "121100 MI", icon: "mdi:road" },
  //     { key: "fuel", label: "Petrol", icon: "mdi:gas-station" },
  //     { key: "drive", label: "AWD", icon: "solar:wheel-bold" },
  //     { key: "availability", label: "Yes", icon: "mdi:magnify" },
  //   ],
  //   features: [
  //     { key: "ABS", label: "ABS", icon: "mingcute:abs-fill" },
  //     { key: "PowerWindows", label: "Power Windows", icon: "mdi:car-door" },
  //     { key: "AirConditioning", label: "Air Conditioning",icon: "material-symbols:ac-unit",
  //     },
  //     {key: "Navigation", label: "Navigation System",icon: "mdi:navigation-outline",
  //     },
  //     { key: "AirBags", label: "Air Bags", icon: "mdi:airbag" },
  //     { key: "CruiseControl",label: "Cruise Control",icon: "mdi:car-cruise-control",
  //     },
  //   ],
  //   timeRemaining: "1d 23h",
  // };

  console.log("carId", id);

  // const CallDealerPage = () => {
  //   navigate("/detail");
  // };

  return (
    <>
      {/* <Navbar /> */}
      <Container>
        <Grid
          container
          spacing={2}
          bgcolor="#fff"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
          padding={{ xs: "1rem 0.5rem", md: 4 }}
          width="100%"
          marginTop={4}
          marginBottom={5}
        >
          <Grid size={{ xs: 12, md: 7 }} sx={{ margin: { xs: "0", md: "0" } }}>
            <Stack>
              <ImageCarousel images={carData?.images} />
            </Stack>
            <Stack paddingTop="36px">
              {/* <Grid container spacing={2} marginBottom={2} justifyContent="space-between">
                {carData.specifications.map((spec) => (
                  <Grid item xs={6} md={2} key={spec.key}>
                    <Stack
                      display="flex"
                      alignItems="center"
                      gap="10px"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding="15px"
                        border="1px solid #EBEBEB"
                        borderRadius="10px"
                      >
                        <Icon
                          icon={spec.icon}
                          style={{ fontSize: "24px", color: "#333" }}
                        />
                      </Box>
                      <Typography variant="body2">{spec.label}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid> */}

              <Grid
                container
                spacing={2}
                marginBottom={2}
                justifyContent="space-between"
              >
                {transformedSpecifications.map((spec) => (
                  <Grid item xs={6} md={2} key={spec.key}>
                    <Stack display="flex" alignItems="center" gap="10px">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding="15px"
                        border="1px solid #EBEBEB"
                        borderRadius="10px"
                      >
                        <Icon
                          icon={spec.icon}
                          style={{ fontSize: "24px", color: "#333" }}
                        />
                      </Box>
                      <Typography variant="body2">{spec.label}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>

              {/* Car Specifications */}
              <Grid container spacing={2} display="block">
                <Grid item xs={6}>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Make{" "}
                    <Typography variant="p3">{carData?.makes?.name}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Transmission{" "}
                    <Typography variant="p3">
                      {carData?.transmission_types?.name}
                    </Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Model Year{" "}
                    <Typography variant="p3">{carData?.modelYear}</Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Seat Capacity{" "}
                    <Typography variant="p3">
                      {carData?.seatCapacity}
                    </Typography>
                  </Typography>
                  <Typography
                    display="flex"
                    justifyContent="space-between"
                    padding="20px 0 15px"
                    borderBottom="1px solid #EBEBEB"
                    variant="p4"
                  >
                    Engine Capacity{" "}
                    <Typography variant="p3">
                      {carData?.engineCapacity}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>

              {/* Features */}
              {/* <Grid
                margin="20px 0"
              >
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  marginBottom={1}
                >
                  Features
                </Typography>
                <Stack direction="row" flexWrap="wrap" gap={1}>
                  {carData.features.map((feature) => (
                    <Stack
                      display="flex"
                      direction="row"
                      alignItems="center"
                      gap={1.6}
                      width="48%"
                    >
                      <Stack
                        key={feature.key}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        padding="15px"
                        border="1px solid #EBEBEB"
                        borderRadius="10px"
                      >
                        <Icon
                          icon={feature.icon}
                          style={{ fontSize: "24px" }}
                        />
                      </Stack>
                      <Typography variant="body2">{feature.label}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid> */}

              {carData && (
                <Grid margin="20px 0">
                  <Typography variant="h6" fontWeight="bold" marginBottom={1}>
                    Features
                  </Typography>
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {transformedFeatures.map((feature) => (
                      <Stack
                        key={feature.key}
                        display="flex"
                        direction="row"
                        alignItems="center"
                        gap={1.6}
                        width="48%"
                      >
                        <Stack
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          padding="15px"
                          border="1px solid #EBEBEB"
                          borderRadius="10px"
                        >
                          <Icon
                            icon={feature.icon}
                            style={{ fontSize: "24px" }}
                          />
                        </Stack>
                        <Typography variant="body2">{feature.label}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Stack>
          </Grid>

          <Grid
            size={{ xs: 12, md: 5 }}
            sx={{ margin: { xs: "0 0.2rem", md: "0" } }}
          >
            {/* Car Details */}
            <Box flex="1">
              <Stack direction="row" spacing={1} alignItems="center" mb={2}>
                <AccessTimeIcon color="success" fontSize="small" />
                <Typography variant="body2">
                  {carData?.auctionDuration} remaining
                </Typography>
              </Stack>

              <Stack mb={1}>
                <Typography variant="h8" fontWeight="bold">
                  {carData?.models?.name} - {carData?.makes?.name}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body6">Starting BID</Typography>
                <Typography variant="h7">
                  USD {carData?.reservedPrice}
                </Typography>
              </Stack>
              <List>
                <Divider sx={style} variant="middle" component="li" />
              </List>

              <MuiCard
                sx={{
                  bgcolor: "#F2F2F2",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
              >
                <CardContent style={{ paddingBottom: "8px" }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    marginBottom={1}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Bid Tracker
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      5 Bids made
                    </Typography>
                  </Box>
                  <List disablePadding>
                    {bid.bidTracker.map((bid, index) => (
                      <ListItem
                        key={index}
                        disableGutters
                        sx={{
                          paddingTop: 0,
                          paddingBottom: { xs: "40px", md: "15px" },
                        }}
                      >
                        <ListItemText
                          primary={bid.amount}
                          primaryTypographyProps={{
                            fontSize: "15px",
                            fontFamily: "Domine",
                            fontWeight: 700,
                            color: "#262626",
                          }}
                        />
                        <Box
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row" }}
                          gap={1}
                        >
                          <Button
                            variant="outlinedSecondary"
                            color="secondary"
                            size="small"
                          >
                            Negotiate
                          </Button>
                          <Button
                            variant="filledSecondary"
                            color="success"
                            size="small"
                          // onClick={CallDealerPage}
                          >
                            Accept
                          </Button>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </MuiCard>
            </Box>
          </Grid>
        </Grid>
        <Box></Box>
      </Container>
    </>
  );
};

export default ViewAd;
