import React, { useState, useEffect } from "react";
import { Container, Typography, Stack, Box } from "@mui/material";
import AdViewCard from "../../../components/AdViewCard";
import Car from "../../../assets/images/car.svg";
import { Icon } from "@iconify/react";
import { DirectionsCar as DirectionsCarIcon, LocalGasStation as LocalGasStationIcon, AllInclusive as AllInclusiveIcon, Speed as SpeedIcon } from "@mui/icons-material";
import FilterSidebar from "../../../components/FilterSidebar";
import Grid from "@mui/material/Grid2";
import { Button } from "../../../components";
import Drawer from "../../../components/Drawer";
import { fetchAuction } from "../../../api/services/listingService";
import { useNavigate } from "react-router-dom";


const Auctions = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [carsData, setCarsData] = useState([]);
    const navigate = useNavigate();

    const handleFetchAuction = async () => {
        try {
            const response = await fetchAuction();
            // setCarsData(response);
            const cars = response?.data || [];
            console.log("cars data", cars);
            setCarsData(cars);
        } catch (error) {
            console.error("Error fetching ads:", error);
        }
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    useEffect(() => {
        handleFetchAuction();
    }, []);

    return (
        <>
            <Container style={{ marginTop: "100px", minHeight: "auto" }}>
                <Stack display="flex" alignItems="start" direction="row" width="100%">
                    <Grid container sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 3 }}>
                            <Stack display={{ xs: "none", md: "block" }}>
                                <FilterSidebar />
                            </Stack>
                            <Stack display={{ xs: "flex", md: "none" }} mb={2} justifyContent={'end'} direction={'row'}>
                                <Drawer
                                    button={
                                        <Button variant="contained" color="secondary" sx={{ width: 'auto', height: 'auto', backgroundColor: '#262626' }}
                                            startIcon={<Icon icon="mdi:filter" style={{ color: 'white', fontSize: '20px' }} />}
                                            onClick={toggleDrawer(true)}
                                        >
                                            Add Filter
                                        </Button>
                                    }
                                >
                                    <FilterSidebar />
                                </Drawer>
                            </Stack>
                        </Grid>
                        <Grid item size={{ xs: 12, md: 9 }} >
                            <Box width={"100%"}>
                                <Stack spacing={2}>
                                    <Stack>
                                        {carsData?.map((car) => (
                                            <AdViewCard
                                                key={car.id}
                                                userId={car.user_id}
                                                route={`/auctions/${car.id}`}
                                                listingId={car.id}
                                                image={car?.images[0]}
                                                title={car?.makes.name || "Unknown Model"}
                                                subtitle="Starting BID"
                                                price={`USD ${car?.reservedPrice || "0"}`}
                                                details={[
                                                    { key: "engine", icon: "mdi:engine", value: car?.engineCapacity || "Unknown", },
                                                    { key: "mileage", icon: "mdi:road", value: car?.mileage || "Unknown", },
                                                    { key: "fuel", icon: "mdi:gas-station", value: car?.engine_types.name || "Unknown", },
                                                    { key: "drive", icon: "solar:wheel-bold", value: car?.driveType || "Unknown", },
                                                ]}
                                                time={car?.auctionDuration || "Unknown time"}
                                            // status={car?.status || "Available"}
                                            />
                                        ))}
                                    </Stack>


                                    {/* <AdViewCard
                                        image={Car}
                                        title="2022 Lamborghini Aventador"
                                        subtitle="Starting BID"
                                        price="USD 599,999"
                                        details={[
                                            {
                                                key: "engine",
                                                icon: DirectionsCarIcon,
                                                value: "2.4 Ltr",
                                            },
                                            { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                                            {
                                                key: "fuel",
                                                icon: LocalGasStationIcon,
                                                value: "Petrol",
                                            },
                                            { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
                                        ]}
                                        time="01:23 remaining"
                                    />
                                    <AdViewCard
                                        image={Car}
                                        title="2024 Porsche"
                                        subtitle="Starting BID"
                                        price="USD 599,999"
                                        details={[
                                            {
                                                key: "engine",
                                                icon: DirectionsCarIcon,
                                                value: "2.4 Ltr",
                                            },
                                            { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                                            {
                                                key: "fuel",
                                                icon: LocalGasStationIcon,
                                                value: "Petrol",
                                            },
                                            { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
                                        ]}
                                        time="02:20 remaining"
                                    />
                                    <AdViewCard
                                        image={Car}
                                        title="2024 Porsche"
                                        subtitle="Starting BID"
                                        price="USD 599,999"
                                        details={[
                                            {
                                                key: "engine",
                                                icon: DirectionsCarIcon,
                                                value: "2.4 Ltr",
                                            },
                                            { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                                            {
                                                key: "fuel",
                                                icon: LocalGasStationIcon,
                                                value: "Petrol",
                                            },
                                            { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
                                        ]}
                                        status="Sold"
                                    />
                                    <AdViewCard
                                        image={Car}
                                        title="2024 Porsche"
                                        subtitle="Starting BID"
                                        price="USD 599,999"
                                        details={[
                                            {
                                                key: "engine",
                                                icon: DirectionsCarIcon,
                                                value: "2.4 Ltr",
                                            },
                                            { key: "mileage", icon: SpeedIcon, value: "121100 MI" },
                                            {
                                                key: "fuel",
                                                icon: LocalGasStationIcon,
                                                value: "Petrol",
                                            },
                                            { key: "drive", icon: AllInclusiveIcon, value: "AWD" },
                                        ]}
                                        status="In Review"
                                    /> */}
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </>
    );
};

export default Auctions;
