export const API_ENDPOINTS = {
  USER: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/sign-up',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    FETCH: '/user',
    UPDATE: '/user',
    UPLOAD: '/user/upload',
  },
  LISTING: {
    MAKES: '/listing/makes',
    MODELS: '/listing/models/:makeId',
    TRANSMISSION_TYPES: '/listing/transmission-types',
    ENGINE_TYPES: '/listing/engine-types',
    BODY_TYPES: '/listing/body-types',
    FEATURES: '/listing/features',
    CREATE_AD: '/listing/consumer',
    FETCH_AD: '/listing/consumer',
    VIEW_AD: '/listing/:listingId',
    UPDATE_AD: '/listing/consumer/:listingId',
    DECODE_VIN: '/listing/consumer/decode-vin',
    UPLOAD_IMAGES: '/upload/fetch-file',
    ALL_AUCTIONS: 'listing/auctions',
  },
  // AUCTION: {
  //   ALL_AUCTIONS: ''
  // }
};
