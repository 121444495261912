import React from 'react';
import PropTypes from 'prop-types';
import styles from './Logo.module.scss';
import logo from '../../assets/logo/logo.png';

const Logo = ({ width , height }) => {
  return (
    <img
      className={styles.CompanyLogo} 
      src={logo} 
      alt="Get A Bid Logo" 
      width={width} 
      height={height} 
    />
  );
};

Logo.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Logo;
