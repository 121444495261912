import React, { useState } from 'react';
import { Tooltip as MUIToolTip, Typography, ClickAwayListener } from '@mui/material';

const Tooltip = ({ title, placement, children }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <MUIToolTip
                    title={<Typography variant="body2" sx={{ color: 'white' }}>{title}</Typography>}
                    arrow
                    placement={placement}
                    open={open}
                    onClose={handleTooltipClose}
                    onOpen={handleTooltipOpen}
                    // disableHoverListener
                    // disableFocusListener
                    // disableTouchListener
                    sx={{ xs: { pointerEvents: 'auto', }, md: { pointerEvents: 'none', }, }}
                >
                    <div onClick={handleTooltipOpen} sx={{ cursor: 'pointer' }}>
                        {children}
                    </div>
                </MUIToolTip>
            </div>
        </ClickAwayListener>
    );
};

export default Tooltip;