import axiosInstance from '../axiosInstance';
import { API_ENDPOINTS } from '../endpoints';

export const getMake = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.MAKES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getModelByMakeId = async (makeId) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.MODELS.replace(':makeId', makeId);
    const response = await axiosInstance.get(endpoint);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransmissionTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.TRANSMISSION_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBodyTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.BODY_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEngineTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.ENGINE_TYPES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFeatures = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.FEATURES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAd = async (formData) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.LISTING.CREATE_AD, formData, 
    // {timeout:30000},  
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.log("error",error)
    throw error;
  }
};

export const fetchAd = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.FETCH_AD);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const viewAd = async (Id) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.VIEW_AD.replace(':listingId', Id);
    const response = await axiosInstance.get(endpoint);
    // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const updateAd = async (listingId, formData) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.UPDATE_AD.replace(':listingId', listingId);
    const response = await axiosInstance.put(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    // const response = await axiosInstance.get(API_ENDPOINTS.LISTING.);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVinNo = async (vin) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.DECODE_VIN;
    const response = await axiosInstance.get(endpoint, {
      params: {
        vin,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFile = async (url) => {
  try {
    const endpoint = API_ENDPOINTS.LISTING.UPLOAD_IMAGES;
    const response = await axiosInstance.get(endpoint, {
      params: {
        url,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAuction = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LISTING.ALL_AUCTIONS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

