import * as React from "react";
import { AppBar, Box, Toolbar, IconButton, ImageListItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiButton from "@mui/material/Button";
import { Button, } from "../../index";
import logo from "../../../assets/logo/logo.png";
import { useNavigate } from "react-router-dom";

const PublicNavbar = () => {
    const navigate = useNavigate()

    const pages = ["Home", "About", "Contact Us"];

    const routeMap = {
        "Home": "/",
        "About": "/about",
        "Contact Us": "/contactus",
    };

    const handleNavigation = (page) => {
        const route = routeMap[page];
        if (route) {
            navigate(route);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ flexGrow: 1, boxShadow: { xs: 'inherit', md: "none" }, backgroundColor: "#ffffff !important", marginTop: { xs: '5px', md: '0px' } }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        color="inherit"
                        sx={{ display: { xs: "flex", md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <ImageListItem sx={{ width: { xs: '50px', sm: 'auto' } }}>
                        <img src={logo} alt="Get A Bid Logo" />
                    </ImageListItem>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "end", gap: "30px" }}>
                        {pages.map((page) => (
                            <MuiButton
                                key={page}
                                onClick={() => handleNavigation(page)}
                                color="secondary"
                                sx={{ my: 2, display: "block", fontFamily: "Nunito", fontSize: '18px' }}
                            >
                                {page}
                            </MuiButton>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex', md: "flex" } }}>
                        <Button
                            onClick={() => navigate('/login')}
                            sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' }, marginRight: '24px' }}
                        >
                            Sign In
                        </Button>
                        <Button
                        onClick={() => navigate('/signup')}
                            sx={{ textTransform: "capitalize", fontWeight: '700', fontSize: { xs: '12px', sm: '16px' }, height: { xs: 'auto', sm: 'auto' }, backgroundColor: { xs: "transparent", sm: '#22B14B' }, color: { xs: 'black', sm: '#FFFFFF' }, padding: { xs: '0px', sm: '12px' } }}
                        >
                            Join now
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ marginTop: '100px' }} />
        </Box>
    );
};

export default PublicNavbar;
