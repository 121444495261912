// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetScreen_CompanyLogo__lZy-z {
  margin-left: 5%;
  margin-top: 1%;
}`, "",{"version":3,"sources":["webpack://./src/screens/Auth/ResetScreen/ResetScreen.module.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.CompanyLogo{\n    margin-left: 5%;\n    margin-top: 1%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CompanyLogo": `ResetScreen_CompanyLogo__lZy-z`
};
export default ___CSS_LOADER_EXPORT___;
