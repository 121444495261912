// MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Footer, PrivateNavbar, PublicNavbar } from '../components';

const MainLayout = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {isAuthenticated && <PrivateNavbar />}
            <Outlet />
            <Footer/>
        </>
    );
};

export default MainLayout;
