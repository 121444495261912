import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardMedia, Typography, IconButton, Box, Menu, MenuItem, Button, Stack, } from "@mui/material";
import { MoreVert as MoreVertIcon, AccessTime as AccessTimeIcon, } from "@mui/icons-material";
import Styles from "./AdViewCard.module.scss";
import { Icon } from "@iconify/react";

const AdViewCard = ({ userId, route, listingId, image, title, subtitle, price, details, time, status, }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewAD = () => {
    navigate(route);
  };

  const handleUpdateAd = () => {
    navigate(`/ads/edit/${listingId}`);
    // console.log('listingId',listingId)
  }

  const cardStyles = {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { xs: "flex-start", sm: "center" },
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    padding: 2,
    mb: 2,
    maxWidth: "100%",
    position: "relative",
  };

  return (
    <Card sx={cardStyles}>
      <IconButton
        onClick={handleMenuOpen}
        sx={{ position: "absolute", top: 8, right: 8, zIndex: 999, }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleUpdateAd}>Edit</MenuItem>
        <MenuItem onClick={handleMenuClose}>Remove</MenuItem>
        <MenuItem onClick={handleMenuClose}>Repost</MenuItem>
      </Menu>

      <CardMedia
        component="img"
        sx={{ width: "132px", height: "auto", borderRadius: "10px", }}
        image={image}
        alt="car"
      />
      <Box
        sx={{ flex: 1, ml: { xs: 0, sm: 3 }, mt: { xs: 1, sm: 0 }, width: { xs: "100%", md: "auto" }, }}
      >
        <Stack marginBottom={2}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1rem", sm: "1.125rem" },
            }}
          >
            {title}
          </Typography>
          <Typography variant="p5" color="textSecondary">
            {subtitle}
            <Typography variant="p5" color="black" marginLeft={1}>
              {price}
            </Typography>
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center" mt={1} flexWrap="wrap">
            {details.map(({ key, icon, value }) => (
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                key={key}
                className={Styles.detailCar}
              >
                <Icon icon={icon} color="#22B14B" fontSize="large" />
                <Typography variant="body4">{value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack
          sx={{ gap: { xs: "20px", sm: "0" } }}
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "start", md: "center" }}
          justifyContent="space-between"
        >
          {status ? (
            <Stack marginBottom={{ xs: "10px", md: "0" }}>
              <Button
                disabled
                sx={{
                  backgroundColor: "#D3ECFF",
                  color: "#252A31",
                  width: "auto",
                  height: "auto",
                }}
              >
                {status}
              </Button>
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              mt={1}
              marginBottom={{ xs: "10px", md: "0" }}
            >
              <AccessTimeIcon color="success" fontSize="small" />
              <Typography variant="body2">{time}</Typography>
            </Stack>
          )}
          <Button
            variant="filledSecondaryBlack"
            color="secondary"
            onClick={handleViewAD}
          >
            View Details
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default AdViewCard;
