import React from "react";
import { Card as MuiCard, CardContent, Typography, Box } from "@mui/material";

const Card = ({ header, children, sx }) => {
    return (
        <MuiCard sx={sx}>
            <CardContent>
                {header && (
                    <Typography variant="h5" component="div" gutterBottom>
                        {header}
                    </Typography>
                )}
                <Box>
                    {children}
                </Box>
            </CardContent>
        </MuiCard>
    );
};

export default Card;
