import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Grid2 as Grid, Modal, Paper, FormHelperText } from '@mui/material';
import imageCompression from 'browser-image-compression';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from "@iconify/react";
import toast from 'react-hot-toast';

const ImageUpload = ({ maxImages = 8, maxSizeMB = 2, allowedFormats = ['jpeg', 'jpg', 'png', 'gif'], setFieldValue, value, error, helperText }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const fileInputRef = useRef();

    const handleImageChange = async (event) => {
        const files = Array.from(event.target.files);
        let validFiles = [];

        // Compression options
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };

        for (const file of files) {
            const fileFormat = file.type.split('/')[1];
            if (!allowedFormats.includes(fileFormat)) {
                toast.dismiss();
                toast.error(`File format ${fileFormat} is not allowed.`)
                // alert(`File format ${fileFormat} is not allowed. Please select a file in ${allowedFormats.join(', ')} format.`);
            } else if (file.size > maxSizeMB * 1024 * 1024) {
                toast.dismiss();
                toast.error(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`)
                // alert(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`);
            } else {
                try {
                    const compressedBlob = await imageCompression(file, options);

                    const compressedFile = new File([compressedBlob], file.name, {
                        type: compressedBlob.type,
                        lastModified: file.lastModified,
                    });

                    validFiles.push(compressedFile);
                } catch (error) {
                    // console.error("Error compressing image:", error);
                    toast.error("An error occurred while compressing an image. Please try again.");
                }
            }
        }

        if ((value.length + validFiles.length) > maxImages) {
            toast.dismiss();
            toast.error(`You can upload a maximum of ${maxImages} images.`);
            return;
        }

        // Append valid files to the existing files
        const newImages = [...value, ...validFiles];
        setFieldValue("files", newImages);

        // Reset file input
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const removeImage = (index) => {
        const updatedImages = [...value];
        updatedImages.splice(index, 1);
        setFieldValue("files", updatedImages);
    };

    const getImageUrl = (file) => {
        if (typeof file === 'string') {
            // console.log('file if',file)
            return file;
        } else {
            // console.log('file else',file)
            return URL.createObjectURL(file);
        }
    };

    useEffect(() => {
        return () => {
            // Cleanup object URLs created for file previews
            value.forEach((file) => {
                if (typeof file !== 'string') {
                    URL.revokeObjectURL(file);
                }
            });
        };
    }, [value]);

    return (
        <>
            <label style={{ cursor: 'pointer' }}>
                <Box sx={{ border: '1px dashed #22B14B', padding: 2, textAlign: 'center', position: 'relative' }}>
                    <input
                        hidden
                        ref={fileInputRef}
                        accept={allowedFormats.map(format => `image/${format}`).join(', ')}
                        multiple
                        type="file"
                        onChange={handleImageChange}
                    />
                    <IconButton color="primary" component="span" aria-label="upload picture">
                        <AddAPhotoIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="body1" sx={{ color: '#22B14B', fontSize: '14px' }}>Add Photos</Typography>
                </Box>
            </label>

            {value.length > 0 && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {value.map((file, index) => {
                        // Create an object URL for each image file
                        const imageUrl = getImageUrl(file);
                        return (
                            <Grid item key={index}>
                                <Paper elevation={1} sx={{ position: 'relative', cursor: 'pointer' }}>
                                    <img
                                        src={imageUrl} // Use object URL as the source
                                        alt={`Uploaded ${index}`}
                                        style={{ width: 100, height: 80, borderRadius: 5 }}
                                        onClick={() => setPreviewImage(imageUrl)}
                                    />
                                    <IconButton
                                        onClick={() => removeImage(index)}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {error && <FormHelperText error>{helperText}</FormHelperText>}

            <Modal open={!!previewImage} onClose={() => setPreviewImage(null)}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        height: '100%',
                    }}
                >
                    <IconButton
                        onClick={() => setPreviewImage(null)}
                        sx={{ position: 'absolute', top: 20, right: 20, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {previewImage && (
                        <img
                            src={previewImage}
                            alt="Full Size Preview"
                            style={{ maxWidth: '100%', maxHeight: '70%', borderRadius: 10 }}
                        />
                    )}
                </Box>
            </Modal>

            <Grid container spacing={2}>
                <Grid item size={{ xs: 12, md: 6 }}>
                    <Typography variant="body7" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}><Icon icon="material-symbols:check-circle" color='#22B14B' fontSize={'large'} /> Add <strong>maximum {maxImages}</strong> pictures.</Typography>
                </Grid>

                <Grid item size={{ xs: 12, md: 6 }}>
                    <Typography variant="body7" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}><Icon icon="material-symbols:check-circle" color='#22B14B' fontSize={'large'} /> Size should be less than <strong>{maxSizeMB}MB.</strong></Typography>
                </Grid>
                <Grid item size={{ xs: 12, md: 6 }}>
                    <Typography variant="body7" sx={{ display: 'flex', flexWrap: { xs: 'wrap' }, alignItems: 'center', gap: '4px' }}><Icon icon="material-symbols:check-circle" color='#22B14B' fontSize={'large'} /> Photos should be in <strong>{allowedFormats.join(', ')}</strong> format only.</Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default ImageUpload;
