import toast from "react-hot-toast";
import styles from "./Toaster.module.scss"

const Toaster = {
  success(message) {
    toast.dismiss();
    toast.success(message, {
      className: styles.singleLineToast,
    });
  },

  error(message) {
    toast.dismiss();
    toast.error(message, {
      className: styles.singleLineToast,
    });
  },

  async handleApiCall(apiCall, successMessage, onSuccess) {
    try {
      const response = await apiCall();
      this.success(response?.message || successMessage || "Operation successful");

      if (onSuccess) {
        onSuccess(response);
      }

      return response;

    } catch (e) {
      if (e?.response) {
        this.error(e?.response?.data?.message || "Something went wrong!");
      }
      //   else {
      //   this.error("Network error. Please check your internet connection.");
      // }
      else if (e.request) {
        this.error("Network error. Please check your internet connection.");
      } else {
        this.error("An unknown error occurred. Please try again later.");
      }
    }
  }
}

export default Toaster;
