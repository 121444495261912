import React, { useState } from 'react';
import { TextField as MUITextField, InputAdornment, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import styles from './TextInput.module.scss';

const iconMap = {
  fullName: "gg:profile",
  email: "ic:outline-mail",
  password: "ri:lock-password-line",
  cnic: "flowbite:profile-card-solid",
  license: "lineicons:license",
  address: "fa6-regular:address-book",
  phoneNumber: "line-md:phone"
};


const TextInput = ({ name, type, value, label, placeholder, error, helperText, ...props }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <MUITextField
      variant="outlined"
      fullWidth
      name={name}
      type={name === 'password' && isPasswordVisible ? 'text' : type}
      placeholder={placeholder}
      className={styles.textField}
      autoComplete='off'
      autoCapitalize='off'
      value={value}
      label={!value ? label : ''}
      error={Boolean(error)}
      helperText={helperText}
      slotProps={{
        input: {
          startAdornment: iconMap[name] && (
            <InputAdornment position="start">
              <Icon icon={iconMap[name]} />
            </InputAdornment>
          ),
          endAdornment: name === 'password' && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                <Icon icon={isPasswordVisible ? 'mdi:eye-off-outline' : 'mdi:eye-outline'} />
              </IconButton>
            </InputAdornment>
          ),
        },
      }
      }
      {...props}
    />
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextInput;
