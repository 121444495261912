import * as Yup from 'yup';
import * as React from "react";
import { Formik } from 'formik';
import Options from './data.json'
import toast from 'react-hot-toast';
import { Icon } from '@iconify/react';
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createAd, fetchVinNo } from "../../../api/services/listingService";
import { Stack, Box, Typography, Container, Grid2 as Grid, Card as MuiCard, IconButton } from "@mui/material";
import { Button, TextInput, SelectInput, ImageUpload, Card, Checkbox, Toaster, RadioInput, FileUpload, Tooltip } from "../../../components";
import { getMake, getModelByMakeId, getTransmissionTypes, getBodyTypes, getEngineTypes, getFeatures } from "../../../api/services/listingService";
import {
  reservedBidValidation, modelValidation, modelYearValidation,
  makeValidation, transmissionValidation, engineCapacityValidation,
  engineTypeValidation, bodyTypeValidation, mileageValidation,
  locationValidation, seatCapacityValidation, exteriorColorValidation,
  interiorColorValidation, filesValidation, featuresValidation,
  startingBidValidation, loanOrLeaseStatusValidation, companyNameValidation, dueAmountValidation, keyFobsValidation,
  exteriorDamagesStatusValidation, interiorDamagesStatusValidation, mechanicalIssuesStatusValidation,
  modificationStatusValidation, tireConditionValidation, conditionRatingValidation, accidentHistoryValidation,
  exteriorDamagesValidation, interiorDamagesValidation, mechanicalIssuesValidation, modificationValidation,
} from "../../../utils/validation"

const validationSchema = Yup.object().shape({
  reservedBid: reservedBidValidation,
  modelYear: modelYearValidation,
  make: makeValidation,
  model: modelValidation,
  transmission: transmissionValidation,
  engineType: engineTypeValidation,
  bodyType: bodyTypeValidation,
  mileage: mileageValidation,
  location: locationValidation,
  engineCapacity: engineCapacityValidation,
  seatCapacity: seatCapacityValidation,
  exteriorColor: exteriorColorValidation,
  interiorColor: interiorColorValidation,
  features: featuresValidation,
  files: filesValidation,
  driversLicense: filesValidation,
  vehicleRegistration: filesValidation,
  startingBid: startingBidValidation,
  loanOrLeaseStatus: loanOrLeaseStatusValidation,
  exteriorDamagesStatus: exteriorDamagesStatusValidation,
  interiorDamagesStatus: interiorDamagesStatusValidation,
  mechanicalIssuesStatus: mechanicalIssuesStatusValidation,
  modificationStatus: modificationStatusValidation,
  tireCondition: tireConditionValidation,
  conditionRating: conditionRatingValidation,
  companyName: companyNameValidation,
  dueAmount: dueAmountValidation,
  keyFobs: keyFobsValidation,
  accidentHistory: accidentHistoryValidation,
  exteriorDamages: exteriorDamagesValidation,
  interiorDamages: interiorDamagesValidation,
  mechanicalIssues: mechanicalIssuesValidation,
  modification: modificationValidation,
});

const CreateAd = () => {
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelptions] = useState([]);
  const [transmissionOptions, setTransmissionOptions] = useState([]);
  const [bodyTypeOptions, setBodyTypeOptions] = useState([]);
  const [engineTypeOptions, setEngineTypeOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const [adData, setAdData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const vinNumber = location.state?.vinNumber || '';

  const handleBackClick = () => {
    navigate(-1);
  };

  // const initialValues = {
  //   files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '', location: '', engineCapacity: '', seatCapacity: '', description: '', features: []
  // };

  const initialValues = adData ? {
    files: adData.images || [],
    startingBid: adData.startingBid || '',
    reservedBid: adData.reservedPrice || '',
    modelYear: adData.modelYear || '',
    make: adData.makes?.id || '',
    model: adData.models?.id || '',
    transmission: adData.transmission_types?.id || '',
    engineType: adData.engine_types?.id || '',
    bodyType: adData.body_types?.id || '',
    mileage: adData.mileage || '',
    location: adData.location || '',
    engineCapacity: adData.engineCapacity || '',
    seatCapacity: adData.seatCapacity || '',
    description: adData.description || '',
    exteriorColor: adData.exteriorColor || '',
    interiorColor: adData.interiorColor || '',
    loanOrLeaseStatus: adData.loanOrLeaseStatus || '',
    exteriorDamagesStatus: adData.exteriorDamagesStatus || '',
    exteriorDamages: adData.exteriorDamages || '',
    interiorDamagesStatus: adData.interiorDamagesStatus || '',
    interiorDamages: adData.interiorDamages || '',
    mechanicalIssuesStatus: adData.mechanicalIssuesStatus || '',
    mechanicalIssues: adData.mechanicalIssues || '',
    modificationStatus: adData.modificationStatus || '',
    modification: adData.modification || '',
    keyFobs: adData.keyFobs || '',
    tireCondition: adData.tireCondition || '',
    conditionRating: adData.conditionRating || '',
    driversLicense: adData.driversLicense || [],
    vehicleRegistration: adData.vehicleRegistration || [],
    // features: adData.features || []
    features: adData.features ? adData.features.map(feature => feature.id) : [],
    companyName: adData.companyName || '',
    dueAmount: adData.dueAmount || '',
    accidentHistory: {
      count: adData.accidentHistory?.count || '',
      severity: adData.accidentHistory?.severity || '',
    }
  } : {
    files: [], reservedBid: '', modelYear: '', make: '', model: '', transmission: '', engineType: '', bodyType: '', mileage: '',
    location: '', engineCapacity: '', seatCapacity: '', description: '', features: [], exteriorColor: '', interiorColor: '',
    loanOrLeaseStatus: '', exteriorDamagesStatus: '', exteriorDamages: [], interiorDamagesStatus: '', interiorDamages: [],
    mechanicalIssuesStatus: '', mechanicalIssues: [], modification: '', modificationStatus: '', keyFobs: '', startingBid: '',
    tireCondition: '', conditionRating: '', driversLicense: [], vehicleRegistration: [], companyName: '', dueAmount: '',
    accidentHistory: {
      count: '',
      severity: '',
    }
  };
  console.log('initialValues', initialValues)

  const handleCreateAD = async (values) => {

    console.log("Values", values);
    const formData = new FormData();

    formData.append("reservedPrice", parseFloat(values.reservedBid));
    formData.append("modelYear", values.modelYear);
    formData.append("make", values.make);
    formData.append("model", values.model);
    formData.append("transmissionType", values.transmission);
    formData.append("engineType", values.engineType);
    formData.append("bodyType", parseInt(values.bodyType));
    formData.append("mileage", parseFloat(values.mileage));
    formData.append("location", values.location);
    formData.append("engineCapacity", parseFloat(values.engineCapacity));
    formData.append("seatCapacity", values.seatCapacity);
    formData.append("description", values.description);
    formData.append("featureIds", JSON.stringify(values.features));
    formData.append("startingBid", parseFloat(values.startingBid));
    formData.append("exteriorColor", values.exteriorColor);
    formData.append("interiorColor", values.interiorColor);
    formData.append("loanOrLeaseStatus", values.loanOrLeaseStatus);
    formData.append("companyName", values.companyName);
    if (values.loanOrLeaseStatus === 'loan' || values.loanOrLeaseStatus === 'lease') {
      formData.append("dueAmount", parseFloat(values.dueAmount));
    }
    // formData.append("dueAmount", parseFloat(values.dueAmount));
    formData.append("accidentHistory", JSON.stringify(values.accidentHistory));
    formData.append("condition", values.conditionRating);
    formData.append("exteriorDamage", JSON.stringify(values.exteriorDamages));
    formData.append("interiorDamage", JSON.stringify(values.interiorDamages));
    formData.append("mechanicalIssues", JSON.stringify(values.mechanicalIssues));
    formData.append("modifications", values.modification);
    formData.append("keyFobs", values.keyFobs);
    formData.append("tireCondition", values.tireCondition);

    values.files.forEach((file, index) => formData.append("files", file));
    values.driversLicense.forEach((file, index) => formData.append("driversLicense", file));
    values.vehicleRegistration.forEach((file, index) => formData.append("vehicleRegistration", file));

    await Toaster.handleApiCall(() => createAd(formData),
      "Form submitted successfully.",
      (response) => {

      }
    );
  };

  const fetchModel = async (makeId) => {
    try {
      console.log("makeId", makeId)
      const data = await getModelByMakeId(makeId);
      const options = data?.data?.map((item) => ({
        value: item.id,
        label: item.name
      }));
      setModelptions(options);
    } catch (error) {
      console.error("Error fetching model data:", error);
    }
  };

  const fetchOptions = async (fetchFunction, setOptions, errorMsg) => {
    try {
      const data = await fetchFunction();
      const options = data?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (error) {
      console.error(`Error fetching ${errorMsg} data:`, error);
    }
  };

  const fetchData = async () => {
    try {
      // console.log("vinNumber", vinNumber)
      const response = await fetchVinNo(vinNumber);
      // console.log("fetchVinNo API", response.data);
      const data = response?.data || {};
      setAdData(data);

      if (data.makes?.id) {
        fetchModel(data.makes.id);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    if (vinNumber) {
      // console.log('vinNumber',vinNumber)
      fetchData();
    }
  }, [vinNumber])

  useEffect(() => {
    fetchOptions(getMake, setMakeOptions, "make");
    fetchOptions(getTransmissionTypes, setTransmissionOptions, "transmission");
    fetchOptions(getBodyTypes, setBodyTypeOptions, "body type");
    fetchOptions(getEngineTypes, setEngineTypeOptions, "engine type");
    fetchOptions(getFeatures, setFeaturesOptions, "features");
  }, []);

  return (
    <>
      <Container >
        <Grid container sx={{ width: "100%" }}>
          <Grid item size={{ xs: 12 }} sx={{ width: "100%" }}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              // validateOnMount={false}
              onSubmit={handleCreateAD}
            >

              {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid, dirty }) => (
                <form onSubmit={handleSubmit} autoComplete="off" autoCapitalize="off">
                  <Stack sx={{ boxShadow: "0px 1px 7px 0px #D1D1D1", borderTop: "3px solid #22B14B", padding: { xs: "10px", md: "40px" } }}>
                    <Stack
                      spacing={2}
                      sx={{ marginTop: { xs: "30px" } }}
                    >
                      <Typography variant="h6" mb={2}>
                        Upload Images
                      </Typography>
                      <ImageUpload
                        maxImages={8}
                        maxSizeMB={1}
                        allowedFormats={['jpeg', 'jpg', 'png', 'gif']}
                        value={values.files}
                        setFieldValue={setFieldValue}
                        // error={!!errors.images && !values.images}
                        error={errors.files && touched.files}
                        helperText={errors.files && touched.files && values.files.length === 0 ? errors.files : ''}
                      />

                      <Typography variant="h4" mb={2} mt={2}>
                        Vehicle Details
                      </Typography>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Make*</Typography>
                          <SelectInput
                            name="make"
                            label="Please Select"
                            value={values.make}
                            // setFieldValue={(field, selectedMake) => {
                            //   setFieldValue("make", selectedMake);
                            //   setFieldValue("model", "");
                            //   fetchModel(selectedMake.value);
                            // }}
                            onChange={(event) => {
                              const selectedMake = event.target.value;
                              setFieldValue("make", selectedMake);
                              fetchModel(selectedMake);
                            }}
                            options={makeOptions}
                            error={!!errors.make && !values.make}
                          />
                        </Stack>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Model*</Typography>
                            <SelectInput
                              name="model"
                              label="Please Select"
                              value={values.model}
                              onChange={handleChange}
                              // setFieldValue={setFieldValue}
                              disabled={!values.make}
                              options={modelOptions}
                              error={!!errors.model && !values.model}
                            />
                          </Stack>
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Model Year*</Typography>
                            <SelectInput
                              name="modelYear"
                              label="Please Select"
                              value={values.modelYear}
                              onChange={handleChange}
                              // setFieldValue={setFieldValue}
                              options={Options.modelYearOptions}
                              error={!!errors.modelYear && !values.modelYear}
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Transmission*</Typography>
                          <SelectInput
                            name="transmission"
                            label="Please Select"
                            value={values.transmission}
                            onChange={handleChange}
                            // setFieldValue={setFieldValue}
                            options={transmissionOptions}
                            error={!!errors.transmission && !values.transmission}
                          />
                        </Stack>
                      </Grid>


                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Engine Type*</Typography>
                            <SelectInput
                              name="engineType"
                              label="Please Select"
                              value={values.engineType}
                              onChange={handleChange}
                              // setFieldValue={setFieldValue}
                              options={engineTypeOptions}
                              error={!!errors.engineType && !values.engineType}
                            />
                          </Stack>
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Body Type*</Typography>
                            <SelectInput
                              name="bodyType"
                              label="Please Select"
                              value={values.bodyType}
                              onChange={handleChange}
                              // setFieldValue={setFieldValue}
                              options={bodyTypeOptions}
                              // error={false}
                              error={!!errors.bodyType && !values.bodyType}
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Mileage*</Typography>
                            <TextInput
                              label="Please Enter"
                              name="mileage"
                              value={values.mileage}
                              onChange={handleChange}
                              fullWidth
                              // error={!!errors.mileage && !values.mileage}
                              error={Boolean(errors.mileage)}
                              helperText={errors.mileage &&
                                (errors.mileage !== "Mileage is required" ? errors.mileage : '')
                              }
                            />
                          </Stack>
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Location*</Typography>
                            <TextInput
                              label="Please Enter"
                              name="location"
                              value={values.location}
                              onChange={handleChange}
                              fullWidth
                              error={!!errors.location && !values.location}
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Engine Capacity* (cc)</Typography>
                          <TextInput
                            label="Please Enter"
                            name="engineCapacity"
                            value={values.engineCapacity}
                            onChange={handleChange}
                            fullWidth
                            // error={!!errors.engineCapacity && !values.engineCapacity}
                            error={Boolean(errors.engineCapacity)}
                            helperText={errors.engineCapacity &&
                              (errors.engineCapacity !== "Engine capacity is required" ? errors.engineCapacity : '')
                            }
                          // onBlur={handleBlur}
                          />
                        </Stack>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Seat Capacity*</Typography>
                          <SelectInput
                            name="seatCapacity"
                            label="Please Select"
                            value={values.seatCapacity}
                            onChange={handleChange}
                            // setFieldValue={setFieldValue}
                            options={Options.seatCapacityOptions}
                            error={!!errors.seatCapacity && !values.seatCapacity}
                          />
                        </Stack>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Exterior Color*</Typography>
                            <TextInput
                              label="Please Enter"
                              name="exteriorColor"
                              value={values.exteriorColor}
                              onChange={handleChange}
                              fullWidth
                              error={!!errors.exteriorColor && !values.exteriorColor}
                            />
                          </Stack>
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Interior Color*</Typography>
                            <TextInput
                              label="Please Enter"
                              name="interiorColor"
                              value={values.interiorColor}
                              onChange={handleChange}
                              fullWidth
                              error={!!errors.interiorColor && !values.interiorColor}
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Typography variant="p1">Features</Typography>
                      <Box>
                        {/* <Stack direction={'row'} flexWrap={'wrap'} width={'50%'}> */}
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          flexWrap="wrap"
                          sx={{ width: { xs: '100%', md: '60%' } }}
                        >
                          <Checkbox
                            sx={{ width: { xs: '100%', md: '48%' } }}
                            options={featuresOptions}
                            selectedValues={values.features}
                            onChange={(newFeatures) => setFieldValue("features", newFeatures)}
                            error={errors.features && touched.features}
                            helperText={errors.features && touched.features && values.features.length === 0 ? errors.features : ''}
                          />
                        </Stack>
                      </Box>


                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Loan or Lease Status*</Typography>
                          <RadioInput
                            name="loanOrLeaseStatus"
                            // label="Loan or Lease Status"
                            value={values.loanOrLeaseStatus}
                            options={Options.loanLeaseOptions}
                            onChange={handleChange}
                            error={!!errors.loanOrLeaseStatus && !values.loanOrLeaseStatus}
                            helperText={errors?.loanOrLeaseStatus}
                          />
                        </Stack>
                      </Grid>

                      {values.loanOrLeaseStatus === 'loan' && (
                        <Grid container spacing={2}>
                          <Grid item size={{ xs: 12, md: 6 }}>
                            <Stack spacing={0.5} sx={{ width: "100%" }}>
                              <Typography variant="p1">Company Name*</Typography>
                              <TextInput
                                label="Please Enter Company Name"
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.companyName && !values.companyName}
                              />
                            </Stack>
                          </Grid>
                          <Grid item size={{ xs: 12, md: 6 }}>
                            <Stack spacing={0.5} sx={{ width: "100%" }}>
                              <Typography variant="p1">Remaining Balance*</Typography>
                              <TextInput
                                name="dueAmount"
                                label="Please Enter Remaining Balance"
                                value={values.dueAmount}
                                onChange={handleChange}
                                fullWidth
                                // error={!!errors.dueAmount && !values.dueAmount}
                                error={Boolean(errors.dueAmount)}
                                helperText={errors.dueAmount &&
                                  (errors.dueAmount !== "Remaining balance or payoff amount is required" ? errors.dueAmount : '')
                                }
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      )}

                      {values.loanOrLeaseStatus === 'lease' && (
                        <Grid container spacing={2}>
                          <Grid item size={{ xs: 12, md: 6 }}>
                            <Stack spacing={0.5} sx={{ width: "100%" }}>
                              <Typography variant="p1">Company Name*</Typography>
                              <TextInput
                                label="Please Enter Company Name"
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.companyName && !values.companyName}
                              />
                            </Stack>
                          </Grid>
                          <Grid item size={{ xs: 12, md: 6 }}>
                            <Stack spacing={0.5} sx={{ width: "100%" }}>
                              <Typography variant="p1">Payoff Amount*</Typography>
                              <TextInput
                                label="Please Enter Lease Payoff Amount"
                                name="dueAmount"
                                value={values.dueAmount}
                                onChange={handleChange}
                                fullWidth
                                // error={!!errors.dueAmount && !values.dueAmount}
                                error={Boolean(errors.dueAmount)}
                                helperText={errors.dueAmount &&
                                  (errors.dueAmount !== "Remaining balance or payoff amount is required" ? errors.dueAmount : '')
                                }
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      )}

                      <Stack spacing={0.5} sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Vehicle Condition*</Typography>
                            <RadioInput
                              name="accidentHistory.count"
                              label="Accident History: Any previous accidents or damages?"
                              value={values.accidentHistory?.count}
                              options={Options.vehicleConditionOptions}
                              onChange={handleChange}
                              // error={!!errors.accidentHistory && !values.accidentHistory}
                              // helperText={errors.accidentHistory}
                              error={!!errors.accidentHistory?.count && !values.accidentHistory?.count}
                              helperText={errors.accidentHistory?.count}
                            />
                          </Stack>
                        </Grid>

                        {(values.accidentHistory?.count === '1' || values.accidentHistory?.count === '2') && (
                          <Grid item size={{ xs: 12, md: 12 }}>
                            <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                              <Grid item size={{ xs: 12, md: 12 }}>
                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                  <Typography variant="p1">Please select the accident type</Typography>
                                  <RadioInput
                                    name="accidentHistory.severity"
                                    // label="Please select the accident type"
                                    value={values.accidentHistory?.severity}
                                    options={Options.accidentTypeOptions}
                                    onChange={handleChange}
                                    error={!!errors.accidentHistory?.severity && !values.accidentHistory?.severity}
                                    helperText={errors.accidentHistory?.severity}
                                  />
                                </Stack>
                              </Grid>
                            </MuiCard>
                          </Grid>
                        )}
                      </Stack>

                      <Stack spacing={0.5} sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Exterior Damages*</Typography>
                            <RadioInput
                              name="exteriorDamagesStatus"
                              value={values.exteriorDamagesStatus}
                              options={Options.exteriorDamagesOptions}
                              onChange={handleChange}
                              error={!!errors.exteriorDamagesStatus && !values.exteriorDamagesStatus}
                              helperText={errors.exteriorDamagesStatus}
                            />
                          </Stack>
                        </Grid>

                        {values.exteriorDamagesStatus === 'yes' && (
                          <Grid item size={{ xs: 12, md: 12 }}>
                            <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                              {/* <MuiCard sx={{ padding: '15px' }} > */}
                              <Grid item size={{ xs: 12, md: 12 }}>
                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                  <Typography variant="p1">Please select all applicable damages</Typography>
                                  {/* <Box> */}
                                  <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options.exteriorDamagesList}
                                    selectedValues={values.exteriorDamages}
                                    onChange={(newValues) => setFieldValue("exteriorDamages", newValues)}
                                    error={errors.exteriorDamages && touched.exteriorDamages}
                                    helperText={errors.exteriorDamages && touched.exteriorDamages && values.exteriorDamages.length === 0 ? errors.exteriorDamages : ''}
                                  />
                                  {/* </Box> */}
                                </Stack>
                              </Grid>
                            </MuiCard>
                          </Grid>
                        )}
                      </Stack>


                      <Stack spacing={0.5} sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Interior Damages*</Typography>
                            <RadioInput
                              name="interiorDamagesStatus"
                              // label="Accident History: Any previous accidents or damages?" 
                              value={values.interiorDamagesStatus}
                              options={Options.interiorDamagesOptions}
                              onChange={handleChange}
                              error={!!errors.interiorDamagesStatus && !values.interiorDamagesStatus}
                              helperText={errors.interiorDamagesStatus}
                            />
                          </Stack>
                        </Grid>

                        {values.interiorDamagesStatus === 'yes' && (
                          <Grid item size={{ xs: 12, md: 12 }}>
                            <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                              {/* <MuiCard sx={{ padding: '15px' }} > */}
                              <Grid item size={{ xs: 12, md: 12 }}>
                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                  <Typography variant="p1">Please select all applicable damages</Typography>
                                  {/* <Box> */}
                                  <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options.interiorDamagesList}
                                    selectedValues={values.interiorDamages}
                                    onChange={(newValues) => setFieldValue("interiorDamages", newValues)}
                                    error={errors.interiorDamages && touched.interiorDamages}
                                    helperText={errors.interiorDamages && touched.interiorDamages && values.interiorDamages.length === 0 ? errors.interiorDamages : ''}
                                  />
                                  {/* </Box> */}
                                </Stack>
                              </Grid>
                            </MuiCard>
                          </Grid>
                        )}
                      </Stack>

                      <Stack spacing={0.5} sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Mechanical Issues*</Typography>
                            <RadioInput
                              name="mechanicalIssuesStatus"
                              // label="Accident History: Any previous accidents or damages?" 
                              value={values.mechanicalIssuesStatus}
                              options={Options.mechanicalIssuesOptions}
                              onChange={handleChange}
                              error={!!errors.mechanicalIssuesStatus && !values.mechanicalIssuesStatus}
                              helperText={errors.mechanicalIssuesStatus}
                            />
                          </Stack>
                        </Grid>

                        {values.mechanicalIssuesStatus === 'yes' && (
                          <Grid item size={{ xs: 12, md: 12 }}>
                            <MuiCard sx={{ padding: '15px', boxShadow: 'none', border: '1px solid #E6E6E6' }}>
                              {/* <MuiCard sx={{ padding: '15px' }} > */}
                              <Grid item size={{ xs: 12, md: 12 }}>
                                <Stack spacing={0.5} sx={{ width: "100%" }}>
                                  <Typography variant="p1">Please select all mechanical damages</Typography>
                                  {/* <Box> */}
                                  <Checkbox
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    options={Options.mechanicalIssuesList}
                                    selectedValues={values.mechanicalIssues}
                                    onChange={(newValues) => setFieldValue("mechanicalIssues", newValues)}
                                    error={errors.mechanicalIssues && touched.mechanicalIssues}
                                    helperText={errors.mechanicalIssues && touched.mechanicalIssues && values.mechanicalIssues.length === 0 ? errors.mechanicalIssues : ''}
                                  />
                                  {/* </Box> */}
                                </Stack>
                              </Grid>
                            </MuiCard>
                          </Grid>
                        )}
                      </Stack>


                      <Stack spacing={0.5} sx={{ width: "100%" }}>
                        <Grid item size={{ xs: 12, md: 12 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Typography variant="p1">Modification or Aftermarket Parts*</Typography>
                            <RadioInput
                              name="modificationStatus"
                              // label="Accident History: Any previous accidents or damages?" 
                              value={values.modificationStatus}
                              options={Options.modificationOptions}
                              onChange={handleChange}
                              error={!!errors.modificationStatus && !values.modificationStatus}
                              helperText={errors.modificationStatus}
                            />
                          </Stack>
                        </Grid>

                        {values.modificationStatus === 'yes' && (
                          <TextInput
                            label="Please enter modification details"
                            name="modification"
                            value={values.modification}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            minRows="4"
                            error={!!errors.modification && !values.modification}
                          />
                        )}
                      </Stack>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">How many key fobs do you have*</Typography>
                          <RadioInput
                            name="keyFobs"
                            // label="Accident History: Any previous accidents or damages?" 
                            value={values.keyFobs}
                            options={Options.keyFobsOptions}
                            onChange={handleChange}
                            // error={!!errors.keyFobs && !values.keyFobs}
                            error={!!errors.keyFobs && !values.keyFobs}
                            helperText={errors.keyFobs}
                          />
                        </Stack>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Tire Condition*</Typography>
                          <RadioInput
                            name="tireCondition"
                            // label="Accident History: Any previous accidents or damages?" 
                            value={values.tireCondition}
                            options={Options.tireConditionOptions}
                            onChange={handleChange}
                            error={!!errors.tireCondition && !values.tireCondition}
                            helperText={errors.tireCondition}
                          />
                        </Stack>
                      </Grid>

                      <Grid item size={{ xs: 12, md: 12 }}>
                        <Stack spacing={0.5} sx={{ width: "100%" }}>
                          <Typography variant="p1">Condition Rating*</Typography>
                          <RadioInput
                            name="conditionRating"
                            // label="Accident History: Any previous accidents or damages?" 
                            value={values.conditionRating}
                            options={Options.conditionRatingOptions}
                            onChange={handleChange}
                            error={!!errors?.conditionRating && !values?.conditionRating}
                            helperText={errors?.conditionRating}
                          />
                        </Stack>
                      </Grid>

                      <Typography variant="h4" mb={2} mt={2}>
                        Pricing Setup
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                              <Typography variant="p1">Starting Bid Price* (USD)</Typography>
                              <Tooltip placement="top" title="The starting bid is set to 85% of the lower market recommendation. You can adjust it as needed.">
                                <IconButton size="small">
                                  <Icon icon="mdi:help-circle-outline" color="green" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                            <TextInput
                              label="Please Enter a starting bid price"
                              name="startingBid"
                              value={values.startingBid}
                              onChange={handleChange}
                              fullWidth
                              // error={!!errors.startingBid && !values.startingBid}
                              error={Boolean(errors.startingBid)}
                              helperText={errors.startingBid &&
                                (errors.startingBid !== "Starting bid is required" ? errors.startingBid : '')
                              }
                            />
                          </Stack>
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Stack spacing={0.5} sx={{ width: "100%" }}>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                              <Typography variant="p1">Reserved Price* (USD)</Typography>
                              <Tooltip placement="top" title="The reserve price is set to the average of the market recommendation range to balance buyer interest. You can adjust it if needed.">
                                <IconButton size="small">
                                  <Icon icon="mdi:help-circle-outline" color="green" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                            <TextInput
                              label="Please Enter a Reserved price"
                              name="reservedBid"
                              value={values.reservedBid}
                              onChange={handleChange}
                              fullWidth
                              // error={!!errors.reservedBid && !values.reservedBid}
                              error={Boolean(errors.reservedBid)}
                              helperText={errors.reservedBid &&
                                (errors.reservedBid !== "Reserved Price is required" ? errors.reservedBid : '')
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <Typography variant="h4" mb={2} mt={2}>
                        Ownership Verification
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <FileUpload
                            name="driversLicense"
                            placeholder="Upload Driver's License"
                            label="Upload Driver's License*"
                            maxImages={1}
                            value={values.driversLicense}
                            setFieldValue={setFieldValue}
                            error={errors.driversLicense && touched.driversLicense}
                            helperText={errors.driversLicense && touched.driversLicense && values.driversLicense.length === 0 ? errors.driversLicense : ''}
                          // error={errors.driversLicense && touched.driversLicense}
                          // helperText={errors.driversLicense && touched.driversLicense ? errors.driversLicense : "Please upload a valid driver's license."}
                          />
                        </Grid>

                        <Grid item size={{ xs: 12, md: 6 }}>
                          <FileUpload
                            name="vehicleRegistration"
                            placeholder="Upload Vehicle Registration"
                            label="Upload Vehicle Registration*"
                            maxImages={1}
                            value={values.vehicleRegistration}
                            setFieldValue={setFieldValue}
                            error={errors.vehicleRegistration && touched.vehicleRegistration}
                            helperText={errors.vehicleRegistration && touched.vehicleRegistration && values.vehicleRegistration.length === 0 ? errors.vehicleRegistration : ''}
                          // error={errors.vehicleRegistration && touched.vehicleRegistration}
                          // helperText={errors.vehicleRegistration && touched.vehicleRegistration ? errors.vehicleRegistration : "Please upload a valid vehicle registration."}
                          />
                        </Grid>
                      </Grid>


                      <Typography variant="p1">Description</Typography>
                      <TextInput
                        label="Write description about your car"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        minRows="4"
                      />
                    </Stack>

                  </Stack>


                  <Stack mt={4} flexDirection={"row"} justifyContent="flex-end" gap={'20px'}>
                    <Button
                      variant="outlined"
                      color="transparent"
                      sx={{ width: '140px', }}
                      onClick={handleBackClick}
                    >
                      Back
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      // fullWidth
                      // disabled={!(isValid && dirty)}
                      sx={{ width: '230px', textTransform: 'capitalize' }}
                      onClick={() => {
                        if (!isValid) {
                          toast.dismiss()
                          toast.error('Please fill out all required fields');
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Stack>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CreateAd
